import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStylesCardGlobal = makeStyles((theme: Theme) => ({
  infoPlanCard: {
    minHeight: '27.375rem',
    backgroundColor: `${theme.palette.primary.light} !important`,
    paddingBottom: '0rem !important',
    borderRadius: '15px !important',
    [theme.breakpoints.down('sm')]: {
      minHeight: '7.438rem !important',
    },
  },
  showDesktopPlanCard: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  infoPlanContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '27.375rem',
    padding: '2rem 1rem 1.563rem !important',
    [theme.breakpoints.down('sm')]: {
      minHeight: '7.438rem !important',
      padding: '1.25rem 1rem !important',
    },
  },
  showMobilePlanCard: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  planBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '17.938rem',
    [theme.breakpoints.down('sm')]: {
      minHeight: '4.938rem !important',
    },
  },
  planTitle: {
    fontSize: '18px !important',
    fontWeight: '500 !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px !important',
      fontWeight: '400 !important',
    },
  },
  planMobileContainer: {
    margin: '8px 0px',
  },
  planLink: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
    },
  },
  button: {
    fontFamily: 'Roboto !important',
    fontWeight: '500 !important',
    fontSize: '14px !important',
    letterSpacing: '0.46px !important',
    lineHeight: '26px !important',
    width: '165px',
    height: '30px',
    borderRadius: '10px !important',
    border: `1px solid ${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.main} !important`,
  },
  columnDescription: {
    height: '15%',
    display: 'flex',
    alignItems: 'center',
  },
  details: {
    fontFamily: 'Roboto !important',
    fontWeight: '400 !important',
    fontSize: '12px !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    letterSpacing: '0.15px !important',
    lineHeight: '143% !important',
    cursor: 'pointer',
    display: 'flex',
    gap: '4px',
  },
  elementLink: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: '18px !important',
    height: '18px !important',
  },
  columnPrice: {
    height: '25%',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
}));
