import queryString from 'query-string';
import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getPlans } from '../../api/api';
import CardPlanDetailsDialog from '../../components/CardPlan/CardPlanDetailsDialog';
import CardPlanList from '../../components/CardPlanList';
import CustomBreadcumbs from '../../components/CustomBreadcumbs';
import ContainerPage from '../../components/Layout/ContainerPage';
import BusinessUnitParamsContext from '../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { setCurrentViewLink, setCurrentViewName } from '../../redux/actions/appActions';

const Plan = () => {
  const dispatch = useDispatch();
  const { contextUrl } = useContext(BusinessUnitParamsContext);
  const url: string = contextUrl!;
  const parameterURL = queryString.parse(location.search);

  useEffect(() => {
    const init = async () => {
      try {
        const response = await getPlans(url, parameterURL.id);
        if (response && response.plans && response.plans.length > 0) {
          dispatch(setCurrentViewName({ currentViewName: response.plans[0].name }));
          dispatch(setCurrentViewLink({ currentViewLink: `/plan?id=${parameterURL.id}` }));
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, []);

  return (
    <ContainerPage>
      <CardPlanDetailsDialog />
      <CustomBreadcumbs />
      <CardPlanList sliceParam planId={parameterURL.id} isMobileViewWithoutCarousel />
    </ContainerPage>
  );
};

export default Plan;
