import { FC } from 'react';

import {
  PAYMENT_TYPE_ID_PAYROLL,
  PAYMENT_TYPE_ID_RECURRENT,
  PAYMENT_TYPE_ID_SINGLE_PAYMENT,
} from '../../../constants/constants';
import PayrollPayment from './PayrollPayment';
import RecurrentPayment from './RecurrentPayment';
import SinglePayment from './SinglePayment';

type PaymentsFrequenciesItem = {
  price: string;
  frequency: string;
  order: number;
  paymentFrequencyName?: string;
};

type Props = {
  item?: {
    name?: string;
    description?: string;
    price?: string;
    categories?: Array<any>;
    id?: number;
    paymentsFrequencies?: Array<PaymentsFrequenciesItem>;
    paymentTypeId?: number;
  };
  detailModal?: boolean;
};

const CardPlanAmountsSection: FC<Props> = ({ item = {} }) => {
  const getAmountComponent = () => {
    switch (item.paymentTypeId) {
      case PAYMENT_TYPE_ID_PAYROLL:
        return <PayrollPayment item={item} />;
      case PAYMENT_TYPE_ID_SINGLE_PAYMENT:
        return <SinglePayment item={item} />;
      case PAYMENT_TYPE_ID_RECURRENT:
        return <RecurrentPayment item={item} />;
      default:
        return null;
    }
  };

  return getAmountComponent();
};

export default CardPlanAmountsSection;
