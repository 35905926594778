import { Button, Grid } from '@mui/material';
import { FC, Fragment, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getPlanById } from '../../api/api';
import { ARCHETYPE_WITH_IMAGE, ARCHETYPE_WITHOUT_IMAGE } from '../../constants/constants';
import { HIRE_PLAN } from '../../constants/routes';
import BusinessUnitParamsContext from '../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import PlanCardContent from '../Common/PlanCardContent';
import PaymentContainer from '../Common/PlanCardContent/components/PaymentContainer';
import PaymentContainerWithImage from '../Common/PlanCardContent/components/PaymentContainerWithImage';
import { useStyles } from './styles';

type Props = {
  planId?: number | null;
};

const CardPlanOnePlan: FC<Props> = ({ planId }) => {
  const classes = useStyles();
  const history = useHistory();
  const { contextUrl, portalArchetypeId } = useContext(BusinessUnitParamsContext);
  const [currentPlan, setCurrentPlan] = useState<any>({});
  const url: string = contextUrl!;
  const { t } = useTranslation();

  const goToQuote = (event: SyntheticEvent) => {
    event.preventDefault();
    history.push({ pathname: `${HIRE_PLAN}/${planId}` });
  };

  useEffect(() => {
    const init = async () => {
      try {
        if (planId) {
          const response = await getPlanById(url, planId);
          if (response.plans) setCurrentPlan(response.plans);
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [planId]);

  const handleDownload = () => {
    window.open(currentPlan.url);
  };

  return (
    <Fragment>
      <Grid
        container
        className={
          portalArchetypeId === ARCHETYPE_WITH_IMAGE
            ? classes.cardContainerWithoutPadding
            : classes.cardContainer
        }
      >
        {portalArchetypeId === ARCHETYPE_WITH_IMAGE && (
          <PaymentContainerWithImage t={t} currentPlan={currentPlan} goToQuote={goToQuote} />
        )}
        {portalArchetypeId === ARCHETYPE_WITHOUT_IMAGE && (
          <Fragment>
            <Grid item xs={12} className={classes.mainTitle}>
              {currentPlan?.name}
            </Grid>

            <Grid item xs={12} className={classes.buttonQuoteContainerMobile}>
              <Button
                fullWidth
                variant="contained"
                className={classes.buttonQuote}
                onClick={goToQuote}
              >
                {t('components.cardPlan.button.quote')}
              </Button>
            </Grid>
          </Fragment>
        )}
        {portalArchetypeId === ARCHETYPE_WITHOUT_IMAGE && (
          <div className={classes.contentContainer}>
            <PaymentContainer t={t} currentPlan={currentPlan} classes={classes} />
          </div>
        )}
        <PlanCardContent
          t={t}
          currentPlan={currentPlan}
          classes={classes}
          handleDownload={handleDownload}
          goToQuote={goToQuote}
          onePlanView={true}
        />
        {portalArchetypeId === ARCHETYPE_WITHOUT_IMAGE && (
          <Grid item xs={12} className={classes.buttonQuoteContainer}>
            <Button variant="contained" className={classes.buttonQuote} onClick={goToQuote}>
              {t('components.cardPlan.button.quote')}
            </Button>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

export default CardPlanOnePlan;
