import { FC, useContext } from 'react';

import { ARCHETYPE_WITH_IMAGE, ARCHETYPE_WITHOUT_IMAGE } from '../../constants/constants';
import BusinessUnitParamsContext from '../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import CardPlan from '../CardPlan';
import CardPlanWithImage from '../CardPlanWithImage';

type PaymentsFrequenciesItem = {
  price: string;
  frequency: string;
  order: number;
};

type Props = {
  item?: {
    name?: string;
    description?: string;
    price?: string;
    categories?: Array<any>;
    id?: number;
    paymentsFrequencies?: Array<PaymentsFrequenciesItem>;
    paymentTypeId?: number;
  };
  index: number;
};
const CardPlanWithArcheType: FC<Props> = ({ item = {}, index }) => {
  const { portalArchetypeId } = useContext(BusinessUnitParamsContext);

  const getCardComponent = () => {
    switch (portalArchetypeId) {
      case ARCHETYPE_WITHOUT_IMAGE:
        return <CardPlan key={index} item={item} index={index} />;
      case ARCHETYPE_WITH_IMAGE:
        return <CardPlanWithImage key={index} item={item} index={index} />;
      default:
        return <CardPlan key={index} item={item} index={index} />;
    }
  };

  return getCardComponent();
};

export default CardPlanWithArcheType;
