import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  pricesContainer: {
    margin: '30px 0px',
    [theme.breakpoints.down('sm')]: {
      margin: '12px 0px',
    },
  },
  priceRecurrentAmountContainer: {
    display: 'flex',
    justifyContent: 'end',
    padding: '4px 2px !important',
    alignItems: 'center',
  },
  planAmount: {
    fontSize: '34px !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px !important',
      fontWeight: '500 !important',
    },
  },
  planMultiAmount: {
    maxWidth: '225px',
    fontSize: '22px !important',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px !important',
      fontWeight: '600 !important',
    },
  },
  priceRecurrentFrecuencyContainer: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  planMultiFrequency: {
    maxWidth: '225px',
    fontSize: '14px !important',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
      fontWeight: '400 !important',
    },
  },
  pricesAmountContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
