import { Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, useState } from 'react';

import { PAYMENT_TYPE_ID_SINGLE_PAYMENT } from '../../../../../constants/constants';
import PlanAmountsSection from '../../../../CardPlanOnePlan/components/PlanAmountsSection';
import { useStyles } from './styles';

type Props = {
  t: any;
  currentPlan: any;
  goToQuote: any;
};

const PaymentContainerWithImage: FC<Props> = ({ t, currentPlan, goToQuote }) => {
  const theme = useTheme();
  const smallSize = useMediaQuery(theme.breakpoints.down('sm'));
  const midSize = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const classes = useStyles();
  const [numberSize, setNumberSize] = useState(0);

  return (
    <Grid container spacing={0} className={classes.root}>
      <Grid item xs={smallSize ? 12 : 4}>
        <div
          className={classes.imageContainerDesktop}
          style={{ backgroundImage: `url(${currentPlan.imageUrl})` }}
        />
      </Grid>
      <Grid item xs={smallSize ? 12 : 8} className={classes.infoContainer}>
        <Grid container spacing={0} className={classes.infoPlanContainer}>
          <Grid item xs={12}>
            <Typography fontSize={smallSize ? 18 : 22} fontWeight={smallSize ? 500 : 600}>
              {currentPlan.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={18} fontWeight={500}>
              {`${t('components.cardPlan.detail.price')}:`}
            </Typography>
          </Grid>
          <div
            style={
              numberSize >= 7
                ? { width: '320px' }
                : numberSize === 6
                ? { width: '302px' }
                : numberSize === 5
                ? { width: '284px' }
                : numberSize === 4
                ? { width: '260px' }
                : { width: '230px' }
            }
          >
            <PlanAmountsSection
              item={currentPlan}
              numberSize={numberSize}
              setNumberSize={setNumberSize}
            />
          </div>
          {/* </Grid> */}
          <Grid item xs={12} className={classes.ivaTextContainer}>
            <Typography className={classes.ivaText}>
              {t('components.cardPlan.detail.ivaText')}
            </Typography>
          </Grid>

          <Grid item xs={smallSize ? 12 : 7} className={classes.paymentTypeContainer}>
            <div className={classes.paymentTypeLabel}>{'Tipo de pago:'}</div>
            <div className={classes.paymentTypeValue}>{currentPlan.paymentTypeName}</div>
          </Grid>
          {currentPlan.paymentTypeId === PAYMENT_TYPE_ID_SINGLE_PAYMENT && (
            <Grid item xs={smallSize ? 12 : 7} className={classes.durationContainer}>
              <div className={classes.durationLabel}>{'Duración:'}</div>
              <div className={classes.durationValue}>{`${currentPlan.paymentDurationMonths} ${t(
                'components.cardPlan.detail.duration.month'
              )}`}</div>
            </Grid>
          )}
          <Grid item xs={smallSize ? 12 : 4} className={classes.buttonContainer}>
            <Button
              variant="contained"
              size={smallSize ? 'small' : midSize ? 'medium' : 'large'}
              className={classes.buttonQuote}
              onClick={goToQuote}
            >
              {t('components.cardPlan.button.quote')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaymentContainerWithImage;
