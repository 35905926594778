import { Grid, Link } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

import InputTextController from '../../../../../components/atoms/InputTextController';
import SelectController from '../../../../../components/atoms/SelectController';
import { RELATIONSHIP_OPTIONS } from '../../../constants';
import { LifeInsuranceBeneficiaryList } from '../../../models/LifeInsuranceBeneficiaryInputs';
import AccordionItem from '../../Common/AccordionItem';
import { useStyles } from '../../Common/Styles/elementStyles';

type StepProps = {
  id: number;
  control: Control<LifeInsuranceBeneficiaryList>;
  alert: boolean;
  percentageChange: any;
  watch: any;
  lifeInsuranceBeneficiaryRemove: any;
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatCustom = React.forwardRef<NumberFormat<string>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
        suffix="%"
        decimalScale={2}
        decimalSeparator=","
        thousandSeparator="."
        isAllowed={(values) => +values.value <= 100}
      />
    );
  }
);

const InsuranceLifeFormElement = (props: StepProps) => {
  const { id, control, alert, watch, lifeInsuranceBeneficiaryRemove, percentageChange } = props;

  const [relationships, setRelationships] = useState<Array<object>>([]);
  const classes = useStyles();
  const { t } = useTranslation();
  const percentage = watch(`lifeInsuranceBeneficiaries.${id}.percentage`);

  useEffect(() => {
    setRelationships(
      RELATIONSHIP_OPTIONS.map((item) => {
        return {
          label: item.name,
          value: item.code,
        };
      })
    );
  }, []);

  useEffect(() => {
    percentageChange();
  }, [percentage]);

  const handleDelete = (e: any) => {
    e.preventDefault();
    lifeInsuranceBeneficiaryRemove(id);
  };

  return (
    <AccordionItem
      id={id}
      title={t('pages.hirePlan.beneficiary.title', { id: id + 1 })}
      alert={alert}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} />
        <Grid item xs={12} sm={6}>
          <InputTextController
            name={`lifeInsuranceBeneficiaries.${id}.name`}
            label={t('pages.hirePlan.titular.form.name')}
            placeholder={t('pages.hirePlan.titular.form.name')}
            control={control}
            required={true}
            minLength={1}
            className={classes.formInput}
            onlyLetters
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputTextController
            name={`lifeInsuranceBeneficiaries.${id}.lastName`}
            label={t('pages.hirePlan.titular.form.lastName')}
            placeholder={t('pages.hirePlan.titular.form.lastName')}
            control={control}
            required={true}
            minLength={1}
            className={classes.formInput}
            onlyLetters
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputTextController
            name={`lifeInsuranceBeneficiaries.${id}.email`}
            label={t('pages.hirePlan.titular.form.email')}
            placeholder={t('pages.hirePlan.titular.form.email')}
            type={'email'}
            control={control}
            required={true}
            minLength={1}
            className={classes.formInput}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectController
            name={`lifeInsuranceBeneficiaries.${id}.relationship`}
            control={control}
            data={relationships}
            label={t('pages.hirePlan.titular.form.relationship')}
            className={classes.formSelect}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputTextController
            name={`lifeInsuranceBeneficiaries.${id}.percentage`}
            label={t('pages.hirePlan.lifeInsuranceBeneficiariy.form.percentage.label')}
            placeholder={t('pages.hirePlan.lifeInsuranceBeneficiariy.form.percentage')}
            control={control}
            required={true}
            minLength={1}
            className={classes.formInput}
            InputProps={{
              inputComponent: NumberFormatCustom as any,
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.buttonContainer}>
          {id !== 0 && (
            <Link href="#" onClick={handleDelete} className={classes.link} underline="hover">
              {t('pages.hirePlan.beneficiary.button.delete')}
            </Link>
          )}
        </Grid>
      </Grid>
    </AccordionItem>
  );
};

export default InsuranceLifeFormElement;
