import { Box, Typography } from '@mui/material';
import classNames from 'classnames';
import { useContext } from 'react';

import BusinessUnitParamsContext from '../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import Menu from './Menu';
import { useStyles } from './styles';

const Header = () => {
  const classes = useStyles();
  const { logo_url, name, showNameInHeader } = useContext(BusinessUnitParamsContext);

  const goToHome = () => {
    window.location.replace('/');
  };

  const showNameMobile = () => {
    return name && name?.length <= 20;
  };

  return (
    <Box className={classes.root}>
      <div className={classes.containerLogoAndTitle}>
        <div className={classes.containerTitle}>
          <img className={classes.logo} src={logo_url} alt={'Business-logo'} onClick={goToHome} />
        </div>
        {showNameInHeader && (
          <div className={classes.containerTitle}>
            <Typography
              className={classNames(classes.title, !showNameMobile() && classes.titleMobile)}
            >
              {name}
            </Typography>
          </div>
        )}
      </div>
      <Menu />
    </Box>
  );
};

export default Header;
