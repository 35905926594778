import { CssBaseline, GlobalStyles } from '@mui/material/';
import { FC } from 'react';
import { Provider } from 'react-redux';

import { BusinessUnitParamsProvider } from './contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { ThemeProvider } from './contexts/ThemeProviderContext/themeProviderContext';
import store from './redux/store';
import { Routes } from './routes';
import { globalStyles } from './styles/global/globalStyles';

const App: FC = () => {
  return (
    <Provider store={store}>
      <BusinessUnitParamsProvider>
        <ThemeProvider>
          <CssBaseline />
          <Routes />
          <GlobalStyles styles={globalStyles} />
        </ThemeProvider>
      </BusinessUnitParamsProvider>
    </Provider>
  );
};

export default App;
