import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gap: '8px',
    },
  },
  grow: {
    flexGrow: '1',
  },
  paymentContainer: {
    borderRadius: '15px',
    gap: 15,
    display: 'grid',
    padding: 16,
    height: '145px',
    boxShadow:
      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    background: '#FAFAFA',
  },
  contentContainer: {
    display: 'grid',
    gap: 20,
    [theme.breakpoints.down('sm')]: {
      gap: 15,
    },
  },
  buttonCancel: {
    margin: '10px !important',
    borderRadius: '15px !important',
    color: `${theme.palette.primary.main} !important`,
    border: `1px solid ${theme.palette.primary.main} !important`,
  },
  buttonSubmit: {
    margin: '10px !important',
    borderRadius: '15px !important',
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  dialogTitleRoot: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  dialogPaper: {
    width: '709px',
    borderRadius: '15px !important',
    maxWidth: 'unset !important',
  },
}));
