import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: 'auto',
  },
  imageContainerDesktop: {
    width: '100%',
    height: '250px',
    display: 'flex',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '15px 150px 150px 0',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      height: '152px',
      borderRadius: '20px 20px 0 0',
    },
  },
  infoContainer: {
    width: '62%',
    display: 'flex',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    paddingTop: '20px',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
  },
  buttonQuote: {
    borderRadius: '20px !important',
    height: '40px !important',
    width: '100%',
    background: `${theme.palette.primary.main} !important`,
    [theme.breakpoints.between('sm', 'md')]: {
      maxWidth: '146px !important',
      height: '36px !important',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '129px !important',
      height: '30px !important',
    },
  },
  infoPlanContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 24px 0 40px !important',
    gap: '16px !important',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 24px 0px 16px !important',
    },
  },
  ivaTextContainer: {
    marginTop: '-8px !important',
  },
  ivaText: {
    fontSize: '12px !important',
    color: 'rgba(117, 117, 117, 1) !important',
  },
  paymentTypeContainer: {
    display: 'flex',
    alignItems: 'end',
  },
  paymentTypeLabel: {
    fontSize: 18,
    fontWeight: 500,
    marginRight: 8,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  paymentTypeValue: {
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  durationContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  durationLabel: {
    fontSize: 18,
    fontWeight: 500,
    marginRight: 8,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  durationValue: {
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
}));
