import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';

import API from '../../api/api';
import ErrorPage from '../../components/ErrorPage';
import Spinner from '../../components/Spinner';
import {
  CURRENCY_ISO_CODE_MEXICO,
  LOGO_DEFAULT,
  TAG_MANAGER_TUTEN_KEY,
  URL_DEFAULT,
  URL_LOCALHOST_DEFAULT,
} from '../../constants/constants';
import { getDefaultI18nextInstance } from '../../i18n/index';
import { BusinessUnitParams } from '../../models/BusinessUnitParamsModel';
import { createScriptGoogleTagManager } from '../../util/commons';

type ContextType = Partial<BusinessUnitParams>;
const BusinessUnitParamsContext = createContext<ContextType>({});
export default BusinessUnitParamsContext;

export const BusinessUnitParamsProvider: React.FC = ({ children }) => {
  const { error, isLoading, businessUnitParams } = useBusinessUnitParams();

  if (error) {
    const errorMessage = axios.isAxiosError(error)
      ? error.response?.data?.message || error.message
      : error.message;

    return <ErrorPage errorMessage={errorMessage} />;
  }

  if (isLoading) return <Spinner />;

  setI18nTranslations(businessUnitParams?.filename);

  return (
    <BusinessUnitParamsContext.Provider value={businessUnitParams || {}}>
      {children}
    </BusinessUnitParamsContext.Provider>
  );
};

const setI18nTranslations = (file?: string) => {
  if (file != undefined) {
    getDefaultI18nextInstance({
      path: file,
    });
  } else {
    getDefaultI18nextInstance({
      path: 'https://devfsmdiag.blob.core.windows.net/uatfsm-i18nfiles/58129d49-0a26-477d-b76e-f26c6d29a506-1653404919176.json',
    });
  }
};

function useBusinessUnitParams() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const [businessUnitParams, setBusinessUnitParams] = useState<BusinessUnitParams>();
  const url = window.location.host === URL_LOCALHOST_DEFAULT ? URL_DEFAULT : window.location.host;

  useEffect(() => {
    setIsLoading(true);
    getBusinessUnitParams(url)
      .then((response) => {
        const payload = {
          ...response.salesPortalConfig,
          ...response.language,
          currencyDecimalPlaces:
            response.salesPortalConfig.currencyIsoCode === CURRENCY_ISO_CODE_MEXICO
              ? 0
              : response.salesPortalConfig.currencyDecimalPlaces,
          logo_url:
            response.salesPortalConfig && response.salesPortalConfig.logo_url
              ? response.salesPortalConfig.logo_url
              : LOGO_DEFAULT,
          name:
            response.salesPortalConfig && response.salesPortalConfig.name
              ? response.salesPortalConfig.name
              : '',
          contextUrl: url,
          googleTagManagerCode: response.salesPortalConfig.google_tag_manager_code,
          showNameInHeader: response.salesPortalConfig.showNameInHeader,
        };
        setBusinessUnitParams(payload);
      })
      .catch(setError)
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (!businessUnitParams) return;
    const { favicon_url, name, googleTagManagerCode } = businessUnitParams;
    updateTitle(name);
    updateOrCreateFavIcon(favicon_url);

    createScriptGoogleTagManager(window, document, 'script', 'dataLayer', TAG_MANAGER_TUTEN_KEY, 0);
    createElementsTagManagerInBody('tuten', TAG_MANAGER_TUTEN_KEY, 0);

    if (googleTagManagerCode && googleTagManagerCode != 'null') {
      createScriptGoogleTagManager(
        window,
        document,
        'script',
        'dataLayer',
        googleTagManagerCode,
        2
      );
      createElementsTagManagerInBody('business', googleTagManagerCode, 1);
    }
  }, [businessUnitParams]);

  return { businessUnitParams, isLoading, error };
}

async function getBusinessUnitParams(url: string) {
  const params = { url: url };
  const res = await API.get('/sales-portal/sales-portal-config-by-url', {
    params,
  });
  return res.data;
}

function updateTitle(title: string) {
  document.title = title || 'BO';
}

function updateOrCreateFavIcon(url: string) {
  const currentLink: HTMLLinkElement | null = document.querySelector("link[rel*='icon']");
  if (currentLink) return (currentLink.href = url);
  const newLink = document.createElement('link');
  newLink.type = 'image/x-icon';
  newLink.rel = 'shortcut icon';
  newLink.href = url + '?t=' + Date.now().toString();
  document.getElementsByTagName('head')[0].appendChild(newLink);
}

function createElementsTagManagerInBody(
  title: string,
  googleTagManagerCode: string,
  position: number
) {
  const newIframe = document.createElement('iframe');
  newIframe.title = title;
  newIframe.src = `https://www.googletagmanager.com/ns.html?id=${googleTagManagerCode}`;
  newIframe.height = '0';
  newIframe.width = '0';

  document.getElementsByTagName('noscript')[position].appendChild(newIframe);
}
