import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  paymentType: {
    fontFamily: 'Roboto !important',
    fontWeight: '500 !important',
    fontSize: '14px !important',
    color: 'rgba(0, 0, 0, 0.75) !important',
  },
}));
