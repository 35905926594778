import queryString from 'query-string';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getCategoriesWithPlans, getPlans } from '../../api/api';
import CardPlanDetailsDialog from '../../components/CardPlan/CardPlanDetailsDialog';
import CardPlanMobileWithStepper from '../../components/CardPlan/CardPlanMobileWithStepper';
import CardPlanWithArcheType from '../../components/CardPlanWithArcheType';
import CustomBreadcumbs from '../../components/CustomBreadcumbs';
import ContainerPage from '../../components/Layout/ContainerPage';
import BusinessUnitParamsContext from '../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { setCurrentViewLink, setCurrentViewName } from '../../redux/actions/appActions';
import { getArrayMobile } from '../../util/commons';
import { useStyles } from './styles';

const Category = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { contextUrl } = useContext(BusinessUnitParamsContext);
  const [plans, setPlans] = useState([]);
  const [plansMobile, setPlansMobile] = useState<any>([]);
  const [currentCategory, setCurrentCategory] = useState<any>(null);
  const url: string = contextUrl!;
  const parameterURL = queryString.parse(location.search);

  useEffect(() => {
    const init = async () => {
      try {
        const response = await getCategoriesWithPlans(url);
        if (response && response.categories && response.categories.length > 0) {
          const currentCategoryFind = response.categories.find(
            (item: any) => item.id == parameterURL.id
          );
          setCurrentCategory(currentCategoryFind);
          dispatch(setCurrentViewName({ currentViewName: currentCategoryFind.name }));
          dispatch(
            setCurrentViewLink({ currentViewLink: `/category?id=${currentCategoryFind.id}` })
          );
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, []);

  useEffect(() => {
    if (currentCategory) {
      const init = async () => {
        try {
          const response = await getPlans(url);
          if (response && response.plans && response.plans.length > 0) {
            const plansAux = response.plans.filter((currentPlanCategory: any) => {
              return (
                currentPlanCategory.categories.find(
                  (category: any) => category.categoryId == parameterURL.id
                ) != undefined
              );
            });

            setPlans(plansAux);

            const slicePlanReference = Math.ceil(plansAux.length / 6);
            setPlansMobile(getArrayMobile(slicePlanReference, plansAux));
          }
        } catch (error) {
          console.error(error);
        }
      };

      init();
    }
  }, [currentCategory]);

  return (
    <ContainerPage>
      <CardPlanDetailsDialog />
      <CustomBreadcumbs />

      <div className={classes.cardContainer}>
        {plans.map((item, index) => (
          <CardPlanWithArcheType key={index} item={item} index={index} />
        ))}
      </div>
      <div className={classes.cardContainerMobile}>
        {plansMobile.map((currentPlanArray: any, indexSlice: any) => (
          <CardPlanMobileWithStepper
            key={indexSlice}
            indexSlice={`${indexSlice}-${plansMobile.length}-${currentPlanArray.length}`}
            maxSteps={currentPlanArray.length}
          >
            {currentPlanArray.map((item: any, index: any) => (
              <CardPlanWithArcheType key={`card-${index}`} item={item} index={index} />
            ))}
          </CardPlanMobileWithStepper>
        ))}
      </div>
    </ContainerPage>
  );
};

export default Category;
