import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getCategories } from '../../../api/api';
import Select from '../../../components/Select';
import BusinessUnitParamsContext from '../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';

type ChangeEventHandler = React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;

interface SelectCategoriesProps {
  value?: string;
  onChange?: ChangeEventHandler;
}

function SelectCategories(props: SelectCategoriesProps): JSX.Element {
  const { contextUrl } = useContext(BusinessUnitParamsContext);
  const url: string = contextUrl!;
  const [categories, setCategories] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const init = async () => {
      try {
        const response = await getCategories(url);
        if (response && response.categories && response.categories.length > 0) {
          setCategories(
            response.categories.map((item: any) => {
              return {
                label: item.name,
                value: item.id,
              };
            })
          );
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, []);

  return (
    <Select
      data={categories}
      fullWidth
      placeholder={t('components.layout.selectCategories.placeholder')}
      onChange={props.onChange}
      value={props.value}
    />
  );
}

export default SelectCategories;
