import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  formInput: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white !important',
      color: 'rgba(0, 0, 0, 0.75) !important',
      height: '3.5rem !important',
      fontSize: '14px !important',
    },
  },
  formSelect: {
    '& .MuiOutlinedInput-input.MuiSelect-select': {
      backgroundColor: 'white !important',
      color: 'rgba(0, 0, 0, 0.75) !important',
      fontSize: '14px !important',
    },
  },
  subtitle: {
    marginTop: '0.5rem !important',
    marginBottom: '1rem !important',
  },
}));
