import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Link,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { setCurrentPlanIdToShowDetailsAction } from '../../../../redux/actions/planActions';
import { useStylesCardGlobal } from '../../../../styles/global/cardStyles';
import { formatEllipsis } from '../../../../util/commons';
import { PAYMENT_TYPE_PAYSHEET, PAYMENT_TYPE_RECURRENT } from '../../constants';
import PlanPrices from '../PlanPrices';
import { useStyles } from './styles';

interface RouteParams {
  id: string;
}
type Props = {
  infoToContract: any;
};
const CardHirePlan: FC<Props> = ({ infoToContract }) => {
  const dispatch = useDispatch();
  const params = useParams<RouteParams>();
  const planId = Number(params.id);
  const classes = useStyles();
  const classesCardGlobal = useStylesCardGlobal();
  const { t } = useTranslation();
  const theme = useTheme();
  const smallSize = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDownload = () => {
    window.open(infoToContract?.infoPlan.urlPlan);
  };

  const handleGoToDetails = () => {
    dispatch(setCurrentPlanIdToShowDetailsAction(planId));
  };

  const handleFrequencyName = () => {
    const paymentType = infoToContract?.infoPlan.paymentTypeId;

    if (paymentType === PAYMENT_TYPE_RECURRENT) {
      if (infoToContract?.infoPlan.paymentsFrequencies.length === 1) {
        return `${t(
          'pages.hirePlan.planInfo.payment.frequency'
        )}: ${infoToContract?.infoPlan?.paymentsFrequencies[0].frequency.toLowerCase()}`;
      } else {
        return null;
      }
    } else {
      const paymentDurationMonths = infoToContract?.infoPlan.paymentDurationMonths || 0;
      const monthText =
        paymentDurationMonths === 1
          ? t('pages.hirePlan.planInfo.payment.month')
          : t('pages.hirePlan.planInfo.payment.months');

      return `${t('pages.hirePlan.planInfo.payment.duration')}: ${
        infoToContract?.infoPlan.paymentDurationMonths
      } ${monthText}`;
    }
  };

  return (
    <Grid item xs={smallSize ? 12 : 4} className={classesCardGlobal.showDesktopPlanCard}>
      <Card className={classesCardGlobal.infoPlanCard}>
        <CardContent className={classesCardGlobal.infoPlanContent}>
          <Box className={classesCardGlobal.planBox}>
            <Typography className={classesCardGlobal.planTitle} variant="h2">
              {infoToContract?.infoPlan.name}
            </Typography>
            <Typography className={classes.planDescription} variant="body2">
              {formatEllipsis(infoToContract?.infoPlan.description, 109)}
            </Typography>
            <Chip
              className={classes.planChip}
              label={`${t(
                'pages.hirePlan.planInfo.paymentType'
              )}: ${infoToContract?.infoPlan.paymentTypeName.toLowerCase()}`}
              size="medium"
              color="primary"
            />

            <PlanPrices infoToContract={infoToContract} />

            {infoToContract?.infoPlan.paymentTypeId !== PAYMENT_TYPE_PAYSHEET && (
              <Typography className={classes.planFrequency} variant="body2">
                {handleFrequencyName()}
              </Typography>
            )}

            <Button className={classes.planButton} onClick={handleGoToDetails}>
              {t('pages.hirePlan.planInfo.detail')}
            </Button>
          </Box>
          <Link href="#" color="inherit" onClick={handleDownload} className={classes.downloadLink}>
            {t('pages.hirePlan.planInfo.download')}
          </Link>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CardHirePlan;
