export const TITULAR_DATA_STEP = 1;
export const BENEFICIARY_DATA_STEP = 2;
export const INSURANCE_LIFE_DATA_STEP = 3;
export const RESUME_AND_PAYMENT_STEP = 4;
export const RESUME_AND_PAYMENT_STEP_2 = 5;

export const FORM_NAME_INPUT_ID = 1;
export const FORM_LASTNAME_INPUT_ID = 2;
export const FORM_EMAIL_INPUT_ID = 3;
export const FORM_PHONE_INPUT_ID = 4;
export const FORM_TYPE_AND_ID_INPUT_ID = 5;
export const FORM_NATIONALITY_INPUT_ID = 6;
export const FORM_RELATIONSHIP_INPUT_ID = 7;
export const FORM_ADDRESS_INPUT_ID = 8;
export const FORM_GENDER_INPUT_ID = 9;
export const FORM_DATE_OF_BIRTH_INPUT_ID = 10;
export const FORM_MOBILE_MODEL_BRAND_ID = 11;
export const FORM_ADDITIONAL_FIELD_1_ID = 12;
export const FORM_ADDITIONAL_FIELD_2_ID = 13;
export const FORM_ADDITIONAL_FIELD_3_ID = 14;
export const FORM_ADDITIONAL_FIELD_4_ID = 15;

export const PAYMENT_TYPE_PAYSHEET = 1;
export const PAYMENT_TYPE_UNIQUE = 2;
export const PAYMENT_TYPE_RECURRENT = 3;

export const RELATIONSHIP_OPTIONS = [
  { code: 'partner', name: 'Cónyuge' },
  { code: 'son', name: 'Hijo(a)' },
  { code: 'brother', name: 'Hermano(a)' },
  { code: 'father', name: 'Padre' },
  { code: 'mother', name: 'Madre' },
];
