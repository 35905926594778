import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      gap: '10px',
    },
  },
}));
