import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { Fragment, SyntheticEvent, useContext, useState } from 'react';

import BusinessUnitParamsContext from '../../../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { useStyles } from '../../../../../styles/global/stylesMenuGlobal';
import MenuContent from '../MenuContent';

const MenuMobile = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const { plan_count } = useContext(BusinessUnitParamsContext);

  const handleClick = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {plan_count && plan_count > 1 && (
        <Fragment>
          <IconButton onClick={handleClick}>
            {open ? <CloseIcon className={classes.icon} /> : <MenuIcon className={classes.icon} />}
          </IconButton>
          <MenuContent handleClose={handleClose} anchorEl={anchorEl} open={open} />
        </Fragment>
      )}
    </>
  );
};

export default MenuMobile;
