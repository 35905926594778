import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  endContainer: {
    marginTop: '5px !important',
  },
  containerButton: {
    textAlign: 'right',
    alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
      marginTop: '18px !important',
    },
  },
  alert: {
    [theme.breakpoints.only('sm')]: {
      width: '82%',
    },
  },
  alertWarning: {
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 200px)',
    },
  },
  default: {},
  button: {
    fontFamily: 'Roboto !important',
    fontWeight: '500 !important',
    fontSize: '14px !important',
    letterSpacing: '0.46px !important',
    lineHeight: '26px !important',
    width: '250px',
    height: '36px',
    borderRadius: '10px !important',
    border: `1px solid ${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.main} !important`,
  },
  buttonContainer: {
    textAlign: 'right',
  },
  link: {
    color: `${theme.palette.primary.main} !important`,
    textDecoration: 'underline !important',
  },
}));
