import { SET_CURRENT_DATA_PRIVACY_TERMS } from '../constants/dataPrivacyTermsActionTypes';
import { DataPrivacyTermsActions, DataPrivacyTermsState } from '../constants/dataPrivacyTermsTypes';

const initialState: DataPrivacyTermsState = {
  dataPrivacyTerms: null,
};

export default (state = initialState, action: DataPrivacyTermsActions) => {
  if (action.type === SET_CURRENT_DATA_PRIVACY_TERMS) {
    return {
      dataPrivacyTerms: action.dataPrivacyTerms,
    };
  } else {
    return {
      ...state,
    };
  }
};
