import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC, Fragment, useContext } from 'react';

import BusinessUnitParamsContext from '../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { formatPriceToLocaleAndCurrency } from '../../../util/commons';
import { useStyles } from './styles';

type PaymentsFrequenciesItem = {
  price: string;
  frequency: string;
  order: number;
  paymentFrequencyName?: string;
};

type Props = {
  item?: {
    name?: string;
    description?: string;
    price?: string;
    categories?: Array<any>;
    id?: number;
    paymentsFrequencies?: Array<PaymentsFrequenciesItem>;
  };
  detailModal?: boolean;
};
const CardPlanAmountsSection: FC<Props> = ({ item = {}, detailModal = false }) => {
  const classes = useStyles();
  const theme = useTheme();
  const smallSize = useMediaQuery(theme.breakpoints.down('sm'));
  const { thousandsSeparator, decimalsSeparator, currencyDecimalPlaces, currencySymbol } =
    useContext(BusinessUnitParamsContext);

  const reverseOrder = (a: PaymentsFrequenciesItem, b: PaymentsFrequenciesItem): 0 | 1 | -1 => {
    if (a.order > b.order) {
      return -1;
    }

    if (a.order < b.order) {
      return 1;
    }

    return 0;
  };

  const length = item?.paymentsFrequencies?.length;

  return (
    <Fragment key={item.id}>
      {item.price ? (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography className={detailModal ? classes.priceDetailModal : classes.price}>
              {formatPriceToLocaleAndCurrency(
                item.price,
                thousandsSeparator,
                decimalsSeparator,
                currencyDecimalPlaces,
                currencySymbol,
                false
              )}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={0} className={classes.paymentRecurrentContainer}>
          {item.paymentsFrequencies &&
            item.paymentsFrequencies.sort(reverseOrder).map((paymentFrecuency, index) => {
              const isSetSeparator =
                index % 2 === 0 && item?.paymentsFrequencies?.[index + 1] !== undefined;

              return (
                <Fragment key={index + `${item?.id}`}>
                  <Grid
                    item
                    xs={detailModal && smallSize ? 12 : length && length <= 1 ? 12 : 5}
                    className={classes.priceContainer}
                  >
                    <Grid
                      container
                      className={
                        length && length <= 1
                          ? classes.flexCenterCenter
                          : (index === 1 || index === 3) && !detailModal
                          ? classes.flexStartCenter
                          : index === 2 && length && length <= 3 && !smallSize
                          ? classes.flexCenterCenter
                          : index === 2 && length && length <= 3 && smallSize && !detailModal
                          ? classes.flexCenterCenter
                          : (index === 1 || index === 3) && detailModal && smallSize
                          ? classes.flexEndCenter
                          : (index === 1 || index === 3) && detailModal && !smallSize
                          ? classes.flexStartCenter
                          : classes.flexEndCenter
                      }
                    >
                      <Grid
                        item
                        xs={smallSize ? 6 : 9}
                        className={
                          length && length <= 1
                            ? classes.flexCenterCenter
                            : (index === 1 || index === 3) && !detailModal
                            ? classes.flexStartCenter
                            : index === 2 && length && length <= 3 && !smallSize
                            ? classes.flexCenterCenter
                            : index === 2 && length && length <= 3 && smallSize && !detailModal
                            ? classes.flexCenterCenter
                            : (index === 1 || index === 3) && detailModal && smallSize
                            ? classes.flexEndCenter
                            : (index === 1 || index === 3) && detailModal && !smallSize
                            ? classes.flexStartCenter
                            : classes.flexEndCenter
                        }
                      >
                        <Typography
                          className={
                            detailModal ? classes.priceRecurrentDetailModal : classes.priceRecurrent
                          }
                        >{`${formatPriceToLocaleAndCurrency(
                          paymentFrecuency.price,
                          thousandsSeparator,
                          decimalsSeparator,
                          currencyDecimalPlaces,
                          currencySymbol,
                          false
                        )}`}</Typography>
                        {!(detailModal && smallSize) && (
                          <Typography className={classes.priceFrecuency}>
                            {detailModal
                              ? paymentFrecuency.paymentFrequencyName?.toLocaleLowerCase()
                              : paymentFrecuency.frequency?.toLocaleLowerCase()}
                          </Typography>
                        )}
                      </Grid>

                      {detailModal && smallSize && (
                        <Grid
                          item
                          xs={smallSize ? 6 : 3}
                          className={classes.frecuencyNameDetailModalMobile}
                        >
                          <Typography className={classes.priceFrecuency}>
                            {detailModal
                              ? paymentFrecuency.paymentFrequencyName?.toLocaleLowerCase()
                              : paymentFrecuency.frequency?.toLocaleLowerCase()}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {isSetSeparator && !detailModal && (
                    <Grid item md={1} className={classes.separatorContainer}>
                      <Typography className={classes.separator}>|</Typography>
                    </Grid>
                  )}
                  {isSetSeparator && !smallSize && detailModal && (
                    <Grid item md={1} className={classes.separatorContainer}>
                      <Typography className={classes.separator}>|</Typography>
                    </Grid>
                  )}
                </Fragment>
              );
            })}
        </Grid>
      )}
    </Fragment>
  );
};

export default CardPlanAmountsSection;
