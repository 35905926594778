import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  cardContainerMobile: {
    gap: '25px 16px',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  paperRoot: {
    padding: '8px 0px !important',
  },
  dotActive: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  button: {
    minWidth: 'auto !important',
    padding: '0px !important',
    color: 'rgba(0, 0, 0, 0.38) !important',
  },
}));
