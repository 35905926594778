import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Link, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC, SyntheticEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { HIRE_PLAN } from '../../constants/routes';
import { setCurrentPlanIdToShowDetailsAction } from '../../redux/actions/planActions';
import { useStylesCardGlobal } from '../../styles/global/cardStyles';
import { formatEllipsis } from '../../util/commons';
import CardPlanAmountsSection from './CardPlanAmountsSection';
import { useStyles } from './styles';

type PaymentsFrequenciesItem = {
  price: string;
  frequency: string;
  order: number;
};

type Props = {
  item?: {
    name?: string;
    description?: string;
    price?: string;
    categories?: Array<any>;
    id?: number;
    paymentsFrequencies?: Array<PaymentsFrequenciesItem>;
    imageUrl?: string;
    paymentTypeId?: number;
  };
  index: number;
};
const CardPlanWithImage: FC<Props> = ({ item = {}, index }) => {
  const history = useHistory();
  const classes = useStyles();
  const classesCardGlobal = useStylesCardGlobal();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mediumSize = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  useEffect(() => {
    let characterCount = 0;
    let count = 0;

    item.categories?.forEach((category) => {
      characterCount += category.categoryName?.length;
      if (characterCount <= (mediumSize ? 30 : 35)) {
        count += 1;
      }
    });
  }, [item.categories, mediumSize]);

  const goToQuote = (event: SyntheticEvent) => {
    event.preventDefault();
    history.push({ pathname: `${HIRE_PLAN}/${item.id}` });
  };

  const goToDetails = () => {
    dispatch(setCurrentPlanIdToShowDetailsAction(item.id));
  };

  const getCategories = () => {
    if (item && item.categories && item.categories?.length > 0) {
      const categories = item.categories.map((item) => item.categoryName);

      let number = 0;
      let counter = 0;
      const categoriesAux: any[] = [];
      categories.forEach((element) => {
        counter = counter + element.length + 2;
        if (counter <= 42) {
          categoriesAux.push(element);
        }
      });

      if (categories.length > categoriesAux.length) {
        number = categories.length - categoriesAux.length;
      }

      return { text: categoriesAux.join(', '), number: number };
    } else return { text: '', number: 0 };
  };

  return (
    <Card className={classes.card} key={index}>
      <CardMedia
        component={'img'}
        alt={item.name}
        image={item.imageUrl}
        classes={{
          media: classes.img,
        }}
      />
      <div className={classes.column}>
        <Typography className={classes.name}>{formatEllipsis(item.name, 70)}</Typography>
      </div>
      <div className={classes.columnCategories}>
        <Typography className={classes.categories}>{`Categoría: ${
          getCategories().text
        } `}</Typography>
        {getCategories().number > 0 && (
          <Typography className={classes.categoriesMore}>{`(+${
            getCategories().number
          })`}</Typography>
        )}
      </div>
      <div className={classesCardGlobal.columnDescription}>
        <Typography className={classes.description}>
          {formatEllipsis(item.description, 190)}
        </Typography>
      </div>
      <div className={classesCardGlobal.columnPrice}>
        <CardPlanAmountsSection item={item} />
      </div>
      <div className={classes.columnButton}>
        <Button variant="contained" size={'small'} className={classes.button} onClick={goToQuote}>
          {t('components.cardPlan.button.quote')}
        </Button>
      </div>
      <div className={classes.columnDetail}>
        <Link className={classesCardGlobal.details} underline="none" onClick={goToDetails}>
          <div className={classesCardGlobal.elementLink}> {t('Ver detalle')}</div>
          <div className={classesCardGlobal.elementLink}>
            <ArrowForwardIcon className={classesCardGlobal.icon} />
          </div>
        </Link>
      </div>
    </Card>
  );
};

export default CardPlanWithImage;
