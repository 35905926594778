import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  formInput: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white !important',
      color: 'rgba(0, 0, 0, 0.75) !important',
      height: '3.5rem !important',
      fontSize: '14px !important',
    },
  },
  formSelect: {
    '& .MuiOutlinedInput-input.MuiSelect-select': {
      backgroundColor: 'white !important',
      color: 'rgba(0, 0, 0, 0.75) !important',
      fontSize: '14px !important',
    },
  },
  checkboxStyle: {
    paddingLeft: '10px',
    color: '#000000DE',
    '& span': {
      fontSize: '14px',
    },
  },
  link: {
    color: `${theme.palette.primary.main} !important`,
  },
  buttonContainer: {
    textAlign: 'right',
  },
  label: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'left',
  },
}));
