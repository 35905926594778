import { Button, Grid, Typography } from '@mui/material';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getPlans } from '../../api/api';
import CardPlanDetailsDialog from '../../components/CardPlan/CardPlanDetailsDialog';
import CardPlanList from '../../components/CardPlanList';
import CardPlanOnePlan from '../../components/CardPlanOnePlan';
import ContainerPage from '../../components/Layout/ContainerPage';
import SelectCategories from '../../components/Layout/SelectCategories';
import { MAX_NUMBER_PLANS_DEFAULT } from '../../constants/constants';
import { PLANS } from '../../constants/routes';
import BusinessUnitParamsContext from '../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { scrollTo } from '../../util/commons';
import { useStyles } from './styles';
import WelcomeSection from './WelcomeSection';

const Home = () => {
  const classes = useStyles();
  const history = useHistory();
  const { contextUrl } = useContext(BusinessUnitParamsContext);
  const url: string = contextUrl!;
  const [planSize, setPlanSize] = useState(0);
  const [planId, setPlanId] = useState<number | null>(null);
  const [filterCategoryValue, setFilterCategoryValue] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    scrollTo();
    const init = async () => {
      try {
        const response = await getPlans(url);
        if (response && response.plans && response.plans.length > 0) {
          setPlanSize(response.plans.length);
          if (response.plans.length === 1) {
            setPlanId(response.plans[0].id);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, []);

  const goToPlans = (event: SyntheticEvent) => {
    event.preventDefault();
    history.push({ pathname: PLANS });
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterCategoryValue(event.target.value);
  };

  return (
    <>
      <CardPlanDetailsDialog />
      <WelcomeSection planSize={planSize} />
      <ContainerPage>
        <Grid container className={classes.container}>
          <Grid item md={9} sm={12} xs={12}>
            <Typography className={classes.title}>
              {planId ? t('pages.home.onePlan.title') : t('pages.home.title')}
            </Typography>
          </Grid>
          {planSize > MAX_NUMBER_PLANS_DEFAULT && planSize > 1 && (
            <Grid item md={3} sm={12} xs={12}>
              <SelectCategories value={filterCategoryValue} onChange={onChange} />
            </Grid>
          )}
        </Grid>
        {planSize > 1 && <CardPlanList sliceParam filterCategoryValue={filterCategoryValue} />}
        {planSize === 1 && <CardPlanOnePlan planId={planId} />}
        {planSize > MAX_NUMBER_PLANS_DEFAULT && (
          <div className={classes.buttonContainer}>
            <Button variant="outlined" className={classes.button} onClick={goToPlans}>
              {t('pages.home.button.viewAllPlans')}
            </Button>
          </div>
        )}
      </ContainerPage>
    </>
  );
};

export default Home;
