import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  text: {
    fontSize: '12px',
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.75) !important',
    fontFamily: 'Roboto',
  },
  text2: {
    fontSize: '12px',
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.38) !important',
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: '34px !important',
    fontFamily: 'Roboto !important',
    color: 'rgba(37, 37, 37, 1)',
  },
  root: {
    marginBottom: '1.563rem',
  },
}));
