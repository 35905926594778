import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    width: '1055px',
    borderRadius: '15px !important',
    maxWidth: 'unset !important',
  },
  grow: {
    flexGrow: '1',
  },
  dialogTitleRoot: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  chips: {
    display: 'flex',
    gap: '20px',
    flexWrap: 'wrap',
    justifyContent: 'left',
    [theme.breakpoints.down('sm')]: {
      gap: 10,
      justifyContent: 'flex-start',
    },
  },
  chip: {
    backgroundColor: `${theme.palette.primary.light} !important`,
    borderRadius: '10px !important',
    fontWeight: '400 !important',
  },
  divider: {
    background: theme.palette.primary.light,
    width: '3px',
    height: '30px',
    margin: '0px 15px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  dividerSection: {
    background: theme.palette.primary.light,
    width: '3px',
    margin: '0px 15px',
  },
  contentContainer: {
    display: 'grid',
    gap: 20,
    [theme.breakpoints.down('sm')]: {
      gap: 15,
    },
  },
  title: {
    fontFamily: 'Roboto !important',
    fontWeight: '500 !important',
    fontSize: '18px !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    letterSpacing: '0.15px !important',
    lineHeight: '28.8px !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px !important',
    },
  },
  subTitle: {
    fontFamily: 'Roboto !important',
    fontWeight: '400 !important',
    fontSize: '14px !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    letterSpacing: '0.15px !important',
    lineHeight: '24px !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
    },
  },
  price: {
    fontFamily: 'Roboto !important',
    fontWeight: '400 !important',
    fontSize: '16px !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    letterSpacing: '0.15px !important',
    maxWidth: '225px',
    textAlign: 'center',
    lineHeight: '24px !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
    },
  },
  button: {
    fontFamily: 'Roboto !important',
    fontWeight: '500 !important',
    fontSize: '14px !important',
    letterSpacing: '0.46px !important',
    lineHeight: '26px !important',
    width: '250px',
    height: '36px',
    borderRadius: '10px !important',
    border: `1px solid ${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.main} !important`,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      display: 'none !important',
    },
  },
  buttonMobile: {
    fontFamily: 'Roboto !important',
    fontWeight: '500 !important',
    fontSize: '14px !important',
    letterSpacing: '0.46px !important',
    lineHeight: '26px !important',
    width: '250px',
    height: '36px',
    borderRadius: '10px !important',
    border: `1px solid ${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.main} !important`,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none !important',
    },
  },
  subTitleContainer: {
    display: 'inline-flex',
  },
  paymentContainer: {
    height: 'auto',
    background: '#FAFAFA',
    boxShadow:
      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    borderRadius: '15px',
    display: 'grid',
    gap: 15,
    padding: '16px 25px',
    [theme.breakpoints.down('sm')]: {
      height: 'auto !important',
    },
  },
  paymentContainerSection: {
    display: 'flex',
  },
  section: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  frequency: {
    borderTop: `2px solid ${theme.palette.primary.light}`,
    paddingTop: '8px',
    display: 'inline-flex',
    alignItems: 'center',
    gap: 10,
  },
  item: {
    border: `2px solid ${theme.palette.primary.light}`,
    borderRadius: '15px !important',
    marginBottom: '-0.938rem !important',
    '& .Mui-expanded': {
      borderTopLeftRadius: '13px',
      borderTopRightRadius: '13px',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
  },
  text: {
    fontSize: 16,
    color: 'rgba(0, 0, 0, 0.87) !important',
  },
  coverageContainer: {
    marginTop: '20px',
    maxHeight: '400px',
    display: 'grid',
    gap: '20px',
    [theme.breakpoints.down('sm')]: {
      gap: 10,
    },
  },
  coverageDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '8px 16px 16px',
    gap: '5px',
    background: '#FAFAFA',
    borderRadius: '4px',
  },
  coverage: {
    display: 'grid',
    gap: '10px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gap: '8px',
    },
  },
  buttonSubmit: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderRadius: '10px !important',
  },
  buttonContainer: {
    padding: '20px 16px !important',
  },
  planName: {
    fontSize: '22px !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px !important',
    },
    fontWeight: '500 !important',
  },
}));
