import { Grid, Typography } from '@mui/material';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';

import Select from '../../../../components/Select';
import BusinessUnitParamsContext from '../../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { formatPriceToLocaleAndCurrency } from '../../../../util/commons';
import { useStyles } from './styles';

type PaymentsFrequenciesItem = {
  price: string;
  frequency: string;
  order: number;
  paymentFrequencyName?: string;
};

type Props = {
  item?: {
    name?: string;
    description?: string;
    price?: string;
    categories?: Array<any>;
    id?: number;
    paymentsFrequencies?: Array<PaymentsFrequenciesItem>;
    paymentTypeName?: string;
    paymentDurationMonths?: string;
  };
  detailModal?: boolean;
  selectWithColorPrimary?: boolean;
};

const RecurrentPayment: FC<Props> = ({ item = {}, selectWithColorPrimary }) => {
  const classes = useStyles();
  const [currentFrequency, setCurrentFrequency] = useState('0');
  const { thousandsSeparator, decimalsSeparator, currencyDecimalPlaces, currencySymbol } =
    useContext(BusinessUnitParamsContext);

  const reverseOrder = (a: any, b: any): 0 | 1 | -1 => {
    if (a.order > b.order) {
      return -1;
    }
    if (a.order < b.order) {
      return 1;
    }
    return 0;
  };

  const currentData = cloneDeep(item['paymentsFrequencies'])?.sort(reverseOrder);

  useEffect(() => {
    if (item && item.paymentsFrequencies) {
      const firstItem = item['paymentsFrequencies'][0].frequency;

      if (currentData) {
        const currentDataFirstItemIndex = currentData.findIndex(
          (item: any) => item.frequency === firstItem
        );
        setCurrentFrequency(`${currentDataFirstItemIndex}`);
      }
    }
  }, []);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentFrequency(event.target.value);
  };

  const getDataPaymentsFrequencies = () => {
    if (currentData) {
      return currentData.map((item: any, index: any) => {
        return {
          label: item.frequency.charAt(0).toUpperCase() + item.frequency.slice(1),
          value: `${index}`,
        };
      });
    } else return [];
  };

  const getSelectClass = () => {
    if (selectWithColorPrimary) {
      return classNames(classes.select, classes.selectColorPrimary);
    } else {
      return classNames(classes.select, classes.selectWithout);
    }
  };

  if (item && item.paymentsFrequencies) {
    if (item.paymentsFrequencies?.length > 1) {
      return (
        <>
          <Grid container xs={12} className={classes.container}>
            <Typography className={classes.price}>
              {formatPriceToLocaleAndCurrency(
                currentData ? currentData[Number(currentFrequency)].price : '0',
                thousandsSeparator,
                decimalsSeparator,
                currencyDecimalPlaces,
                currencySymbol,
                true
              )}
            </Typography>
            <Select
              data={getDataPaymentsFrequencies()}
              onChange={onChange}
              value={currentFrequency}
              className={getSelectClass()}
              MenuClass={classes.options}
              variant={'standard'}
            />
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid container xs={12} className={classes.container}>
            <Typography className={classes.price}>
              {formatPriceToLocaleAndCurrency(
                item['paymentsFrequencies'][0].price,
                thousandsSeparator,
                decimalsSeparator,
                currencyDecimalPlaces,
                currencySymbol,
                true
              )}
            </Typography>
            <Typography className={classes.singlePayment}>
              {`${
                item['paymentsFrequencies'][0].frequency.charAt(0).toUpperCase() +
                item['paymentsFrequencies'][0].frequency.slice(1)
              }`}
            </Typography>
          </Grid>
        </>
      );
    }
  } else {
    return null;
  }
};

export default RecurrentPayment;
