import { FC } from 'react';
import { Redirect, RouteProps } from 'react-router';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Layout from '../components/Layout';
import {
  CATEGORY_ID,
  CATEGORY_PLAN_ID,
  CONTACT_US,
  DATA_PRIVACY_TERMS,
  FREQUENT_QUESTIONS,
  HIRE_PLAN,
  HOME,
  LEGAL_NOTICES,
  PLANS,
  TERMS_AND_CONDITIONS,
} from '../constants/routes';
import Category from '../pages/Category';
import ContactForm from '../pages/ContactForm';
import DataPrivacyTerms from '../pages/DataPrivacyTerms';
import FrequentQuestions from '../pages/FrequentQuestions';
import HirePlan from '../pages/HirePlan';
import Home from '../pages/Home';
import LegalNotices from '../pages/LegalNotices';
import Plan from '../pages/Plan';
import Plans from '../pages/Plans';
import TermsAndConditions from '../pages/TermsAndConditions';

const authRoutes: RouteProps[] = [
  { exact: true, component: Home, path: HOME },
  { exact: true, component: Plans, path: PLANS },
  { exact: true, component: Plan, path: CATEGORY_PLAN_ID },
  { exact: false, component: Category, path: CATEGORY_ID },
  { exact: true, component: FrequentQuestions, path: FREQUENT_QUESTIONS },
  { exact: true, component: TermsAndConditions, path: TERMS_AND_CONDITIONS },
  { exact: true, component: LegalNotices, path: LEGAL_NOTICES },
  { exact: true, component: ContactForm, path: CONTACT_US },
  { exact: true, component: HirePlan, path: `${HIRE_PLAN}/:id` },
  { exact: true, component: DataPrivacyTerms, path: DATA_PRIVACY_TERMS },
  {
    path: '*',
    render: () => <Redirect to={'/'} />,
  },
];

export const Routes: FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={'/'} render={(props) => <Layout routes={authRoutes} {...props} />} />
      </Switch>
    </BrowserRouter>
  );
};
