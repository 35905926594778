import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '65% 35%',
    height: '90px',
    alignItems: 'flex-end',
    width: '100%',
    backgroundColor: '#fff',
    borderBottom: '1px solid #e5e5e5',
    maxHeight: '90px',
    padding: '1rem 4.75rem',
    [theme.breakpoints.down('md')]: {
      padding: '1rem 3.125rem',
      gridTemplateColumns: '55% 45%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '1.938rem 1.5rem',
      gridTemplateColumns: '85% 15%',
      alignItems: 'unset',
      justifyContent: 'space-between',
    },
  },
  containerLogoAndTitle: {
    display: 'inline-flex',
    alignItems: 'center',
    height: '100%',
  },
  logo: {
    maxHeight: '3.125rem',
    maxWidth: '200px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '8rem',
    },
  },
  containerTitle: {
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    padding: '0px 8px',
  },
  title: {
    fontFamily: 'Roboto !important',
    fontSize: '18px !important',
    fontWeight: '500 !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  titleMobile: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));
