import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  rootDesktop: {
    display: 'flex',
    gap: '30px',
    justifyContent: 'end',
    height: '100%',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      gap: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  rootMobile: {
    display: 'flex',
    gap: '20px',
    justifyContent: 'end',
    height: '100%',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));
