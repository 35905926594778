import { Button, Card, CardMedia, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useStylesCardGlobal } from '../../../../styles/global/cardStyles';
import { formatEllipsis } from '../../../../util/commons';
import CardPlanAmountsSection from './CardPlanAmountsSection/';
import CardPlanAmountTypeSection from './CardPlanAmountTypeSection/';
import { useStyles } from './styles';

type Props = {
  infoToContract: any;
};
const CardHirePlanMobileWithImage: FC<Props> = ({ infoToContract }) => {
  const classes = useStyles();
  const classesCardGlobal = useStylesCardGlobal();
  const { t } = useTranslation();

  const handleDownload = () => {
    window.open(infoToContract?.infoPlan.urlPlan);
  };

  return (
    <Grid item xs={12} className={classesCardGlobal.showMobilePlanCard}>
      <Card className={classes.infoPlanCard}>
        <CardMedia
          component={'img'}
          alt={infoToContract?.infoPlan.name}
          image={infoToContract?.infoPlan.imageUrl}
          classes={{
            media: classes.img,
          }}
        />
        <div className={classes.column}>
          <Typography className={classes.name}>
            {formatEllipsis(infoToContract?.infoPlan.businessPlanName, 70)}
          </Typography>
        </div>
        <div className={classes.columnButton}>
          <Button
            variant="outlined"
            className={classesCardGlobal.button}
            onClick={handleDownload}
            size={'small'}
          >
            {t('Descargar plan')}
          </Button>
        </div>
        <div className={classes.columnPriceType}>
          <CardPlanAmountTypeSection item={infoToContract?.infoPlan} />
        </div>
        <div className={classes.columnPrice}>
          <CardPlanAmountsSection item={infoToContract?.infoPlan} />
        </div>
      </Card>
    </Grid>
  );
};

export default CardHirePlanMobileWithImage;
