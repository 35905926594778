import { Box, Divider, Grid, Link, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getExistsFooterLinks, getTermsDataPrivacy } from '../../../api/api';
import { LOGO_DEFAULT } from '../../../constants/constants';
import * as route from '../../../constants/routes';
import BusinessUnitParamsContext from '../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { setCurrentDataPrivacyTerms } from '../../../redux/actions/dataPrivacyTermsActions';
import { Image } from '../../atoms/Image';
import { useStyles } from './styles';

const Footer = () => {
  const classes = useStyles();
  const { logo_url, name, contextUrl, showNameInHeader } = useContext(BusinessUnitParamsContext);
  const url: string = contextUrl!;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showLink, setShowLink] = useState({
    legalNotices: true,
    question: true,
    termsConditions: true,
  });

  useEffect(() => {
    const init = async () => {
      try {
        const response = await getExistsFooterLinks(url);

        if (response && response.termsDataPrivacy) {
          const responseTermsDataPrivacy = await getTermsDataPrivacy(url);
          if (responseTermsDataPrivacy && responseTermsDataPrivacy.termsDataPrivacy) {
            dispatch(
              setCurrentDataPrivacyTerms(responseTermsDataPrivacy.termsDataPrivacy.description)
            );
          }
        }

        setShowLink(response);
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, []);

  return (
    <Box className={classes.root}>
      <Divider />
      <Grid container spacing={0}>
        <Grid item xs={9} sm={6} xl={6}>
          <Box className={classes.leftBox}>
            <Grid container rowSpacing={2}>
              {showLink.termsConditions && (
                <Grid item xs={12} sm={6}>
                  <Link
                    className={classes.navigationText}
                    variant={'body1'}
                    underline="hover"
                    href={route.TERMS_AND_CONDITIONS}
                  >
                    {t('components.layout.footer.termsAndConditions')}
                  </Link>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <Link
                  className={classes.navigationText}
                  variant={'body1'}
                  underline={'hover'}
                  href={route.CONTACT_US}
                  target={'_blank'}
                >
                  {t('components.layout.footer.contactUs')}
                </Link>
              </Grid>
              {showLink.legalNotices && (
                <Grid item xs={12} sm={6}>
                  <Link
                    className={classes.navigationText}
                    variant={'body1'}
                    underline={'hover'}
                    href={route.LEGAL_NOTICES}
                  >
                    {t('components.layout.footer.legalNotices')}
                  </Link>
                </Grid>
              )}
              {showLink.question && (
                <Grid item xs={12} sm={6}>
                  <Link
                    className={classes.navigationText}
                    variant={'body1'}
                    underline={'hover'}
                    href={route.FREQUENT_QUESTIONS}
                  >
                    {t('components.layout.footer.frequentQuestions')}
                  </Link>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={3} sm={6} xl={6}>
          <Box className={classes.rightBox}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8} className={classes.portalNameBox}>
                {showNameInHeader && (
                  <Typography className={classes.portalName} variant={'h2'}>
                    {name}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={4} className={classes.logoBox}>
                <Image
                  src={logo_url ? logo_url : LOGO_DEFAULT}
                  className={classes.logo}
                  onClick={() => window.location.replace(route.HOME)}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
