import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  price: {
    fontFamily: 'Roboto !important',
    fontWeight: '600 !important',
    fontSize: '18px !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    letterSpacing: '0.15px !important',
    lineHeight: '160% !important',
    textAlign: 'center',
  },
  priceDetailModal: {
    fontFamily: 'Roboto !important',
    fontWeight: '400 !important',
    fontSize: '18px !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    letterSpacing: '0.15px !important',
    lineHeight: '160% !important',
    textAlign: 'center',
  },
  priceRecurrent: {
    fontFamily: 'Roboto !important',
    fontWeight: '600 !important',
    fontSize: '18px !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    letterSpacing: '0.15px !important',
    lineHeight: '160% !important',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px !important',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '16px !important',
    },
  },
  priceRecurrentDetailModal: {
    fontFamily: 'Roboto !important',
    fontWeight: '400 !important',
    fontSize: '16px !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    letterSpacing: '0.15px !important',
    lineHeight: '160% !important',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px !important',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '15px !important',
    },
  },
  priceFrecuency: {
    fontFamily: 'Roboto !important',
    fontSize: '12px !important',
    fontWeight: '400 !important',
    marginLeft: '4px !important',
  },
  separatorContainer: {
    display: 'flex !important',
    justifyContent: 'center !important',
    margin: '0px -5px !important',
    [theme.breakpoints.down('md')]: {
      margin: '0px 0px !important',
    },
    paddingRight: '-30px !important',
  },
  priceContainer: {
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
  },
  separator: {
    fontSize: '16px !important',
    fontWeight: '600 !important',
    fontFamily: 'Roboto !important',
    margin: '0px 5px !important',
  },
  paymentRecurrentContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  flexStartCenter: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  flexEndCenter: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  flexCenterCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  frecuencyNameDetailModalMobile: {
    display: 'flex',
    alignItems: 'center',
  },
}));
