import { Box, Card, CardContent, Grid, Link, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useStylesCardGlobal } from '../../../../styles/global/cardStyles';
import PlanPrices from '../PlanPrices';

type Props = {
  infoToContract: any;
};
const CardHirePlanMobile: FC<Props> = ({ infoToContract }) => {
  const classes = useStylesCardGlobal();
  const { t } = useTranslation();

  const handleDownload = () => {
    window.open(infoToContract?.infoPlan.urlPlan);
  };

  return (
    <Grid item xs={12} className={classes.showMobilePlanCard}>
      <Card className={classes.infoPlanCard}>
        <CardContent className={classes.infoPlanContent}>
          <Box className={classes.planBox}>
            <Typography className={classes.planTitle} variant="body2">
              {infoToContract?.infoPlan.name}
            </Typography>

            <Box className={classes.planMobileContainer}>
              <PlanPrices infoToContract={infoToContract} />
            </Box>

            <Link color="inherit" onClick={handleDownload} className={classes.planLink}>
              {t('pages.hirePlan.planInfo.download')}
            </Link>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CardHirePlanMobile;
