import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { useStyles } from '../../../../../styles/global/accordionStyles';

type AccordionItemProps = {
  id: number;
  title: string;
  alert: boolean;
  children: React.ReactNode;
};

const AccordionItem = (props: AccordionItemProps) => {
  const { id, title, alert, children } = props;

  const [expand, setExpand] = useState(true);
  const classes = useStyles();
  const myRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (id !== 0) {
      myRef?.current?.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }, []);

  useEffect(() => {
    alert && setExpand(true);
  }, [alert]);

  return (
    <Box className={classes.container}>
      <Accordion
        className={classes.item}
        defaultExpanded
        expanded={expand}
        onChange={() => setExpand(!expand)}
        ref={myRef}
      >
        <AccordionSummary
          className={classes.title}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.text} variant={'subtitle1'}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails> {children} </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default AccordionItem;
