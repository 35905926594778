import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import BusinessUnitParamsContext from '../../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { PaymentsFrequency } from '../../../../models/HirePlanModel';
import { formatPriceToLocaleAndCurrency } from '../../../../util/commons';
import { useStyles } from './styles';

type Props = {
  options: PaymentsFrequency[];
  isOpenDialog: boolean;
  handleCloseDialog: any;
  handleSubmit: any;
  setPaymentId: any;
};

const MultiPaymentModal = (props: Props) => {
  const classes = useStyles();
  const { handleCloseDialog, handleSubmit, isOpenDialog, options, setPaymentId } = props;
  const { t } = useTranslation();
  const { thousandsSeparator, decimalsSeparator, currencyDecimalPlaces, currencySymbol } =
    useContext(BusinessUnitParamsContext);
  const optionList = [...options].reverse();

  const reverseOrder = (a: any, b: any): 0 | 1 | -1 => {
    if (a.order > b.order) {
      return -1;
    }
    if (a.order < b.order) {
      return 1;
    }
    return 0;
  };

  return (
    <Dialog
      onClose={handleCloseDialog}
      open={isOpenDialog}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
        <div className={classes.header}>{t('pages.hirePlan.multiPaymentModal.title')}</div>
        <div className={classes.grow} />
        <IconButton onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <div className={classes.contentContainer}>
          <Typography>{t('pages.hirePlan.multiPaymentModal.first.body')}</Typography>
          <FormControl>
            {optionList.length > 0 && (
              <RadioGroup defaultValue={0}>
                {optionList.sort(reverseOrder).map((opt, key) => (
                  <FormControlLabel
                    key={key}
                    control={
                      <Radio
                        value={key}
                        onChange={() =>
                          setPaymentId(
                            options
                              .map((x) => x.frequency.toLowerCase())
                              .indexOf(opt.frequency.toLowerCase())
                          )
                        }
                      />
                    }
                    label={`${formatPriceToLocaleAndCurrency(
                      opt.price,
                      thousandsSeparator,
                      decimalsSeparator,
                      currencyDecimalPlaces,
                      currencySymbol,
                      false
                    )} ${opt.frequency.toLowerCase()}`}
                  />
                ))}
              </RadioGroup>
            )}
          </FormControl>
          <Typography>{t('pages.hirePlan.multiPaymentModal.second.body')}</Typography>
        </div>
      </DialogContent>

      <DialogActions>
        <Button className={classes.buttonCancel} variant="outlined" onClick={handleCloseDialog}>
          {t('pages.hirePlan.button.cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          className={classes.buttonSubmit}
        >
          {t('pages.hirePlan.button.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MultiPaymentModal;
