import { Button, Link, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { HIRE_PLAN } from '../../constants/routes';
import { setCurrentPlanIdToShowDetailsAction } from '../../redux/actions/planActions';
import { useStylesCardGlobal } from '../../styles/global/cardStyles';
import { formatEllipsis } from '../../util/commons';
import CardPlanAmountsSection from './CardPlanAmountsSection';
import { useStyles } from './styles';

type PaymentsFrequenciesItem = {
  price: string;
  frequency: string;
  order: number;
};

type Props = {
  item?: {
    name?: string;
    description?: string;
    price?: string;
    categories?: Array<any>;
    id?: number;
    paymentsFrequencies?: Array<PaymentsFrequenciesItem>;
  };
  index: number;
};
const CardPlan: FC<Props> = ({ item = {}, index }) => {
  const history = useHistory();
  const classes = useStyles();
  const classesCardGlobal = useStylesCardGlobal();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [chipsToShow, setChipsToShow] = useState(0);
  const theme = useTheme();
  const mediumSize = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const goToQuote = (event: SyntheticEvent) => {
    event.preventDefault();
    history.push({ pathname: `${HIRE_PLAN}/${item.id}` });
  };

  const goToDetails = () => {
    dispatch(setCurrentPlanIdToShowDetailsAction(item.id));
  };

  useEffect(() => {
    let characterCount = 0;
    let count = 0;

    item.categories?.forEach((category) => {
      characterCount += category.categoryName?.length;
      if (characterCount <= (mediumSize ? 30 : 35)) {
        count += 1;
      }
    });

    setChipsToShow(count);
  }, [item.categories, mediumSize]);

  return (
    <Card className={classes.card} key={index}>
      <div className={classes.column}>
        <Typography className={classes.name}>{formatEllipsis(item.name, 70)}</Typography>
      </div>
      <div className={classes.columnChip}>
        <div className={classes.chips}>
          {item.categories &&
            item.categories.map((itemCategory, indexCategory) => {
              if (indexCategory + 1 <= chipsToShow) {
                return (
                  <Chip
                    classes={{ root: classes.chip }}
                    label={itemCategory.categoryName}
                    key={indexCategory}
                    variant="outlined"
                  />
                );
              } else if (indexCategory + 1 === item.categories?.length) {
                return (
                  <Chip
                    classes={{ root: classes.chip }}
                    label={`+${indexCategory + 1 - chipsToShow}`}
                    key={indexCategory}
                    variant="outlined"
                  />
                );
              }
            })}
        </div>
      </div>
      <div className={classes.columnDouble}>
        <Typography className={classes.description}>
          {formatEllipsis(item.description, 190)}
        </Typography>
      </div>
      <div className={classesCardGlobal.columnPrice}>
        <CardPlanAmountsSection item={item} />
      </div>
      <div className={classes.columnDetail}>
        <Link className={classes.details} onClick={goToDetails}>
          {t('components.cardPlan.button.detail')}
        </Link>
      </div>
      <div className={classes.columnDouble}>
        <Button variant="contained" className={classes.button} onClick={goToQuote} size={'medium'}>
          {t('components.cardPlan.button.quote')}
        </Button>
      </div>
    </Card>
  );
};

export default CardPlan;
