import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  planDescription: {
    fontSize: '16px !important',
    fontWeight: '400 !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    textAlign: 'center',
    padding: '0rem 1.25rem',
    marginTop: '16px !important',
  },
  planChip: {
    fontSize: '14px !important',
    backgroundColor: `${theme.palette.primary.main} !important`,
    height: '2.375rem !important',
    marginTop: '30px',
  },
  planFrequency: {
    fontSize: '16px !important',
  },
  planButton: {
    color: `${theme.palette.primary.main} !important`,
  },
  downloadLink: {
    marginTop: '70px !important',
  },
}));
