import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    backgroundColor: theme.palette.primary.light,
    height: '300px',
    marginTop: '3.12rem',
    [theme.breakpoints.down('sm')]: {
      height: '155px',
      marginTop: '1.12rem',
    },
  },
  leftColumn: {
    width: '50%',
    padding: '2.469rem 2.469rem 0rem 4.75rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '28px',
    [theme.breakpoints.down('md')]: {
      padding: '2.469rem 2.469rem 0rem 3.125rem',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '1.938rem 1.938rem 0rem 1.5rem',
      gap: '16px',
    },
  },
  title: {
    fontFamily: 'Roboto !important',
    fontWeight: '500 !important',
    fontSize: '30px !important',
    color: 'rgba(0, 0, 0, 0.75) !important',
    letterSpacing: '0.25px !important',
    lineHeight: '123.5% !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px !important',
    },
  },
  description: {
    fontFamily: 'Roboto !important',
    fontWeight: '400 !important',
    fontSize: '16px !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    letterSpacing: '0.15px !important',
    lineHeight: '150% !important',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  rightColumn: {
    width: '50%',
    display: 'flex',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '150px 0 0 150px',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
  },
  button: {
    fontFamily: 'Roboto !important',
    fontWeight: '500 !important',
    fontSize: '14px !important',
    letterSpacing: '0.46px !important',
    lineHeight: '26px !important',
    width: '130px',
    height: '42px',
    borderRadius: '10px',
    backgroundColor: `${theme.palette.primary.main} !important`,
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
  },
}));
