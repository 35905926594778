import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import { useStyles } from './styles';

const Menu = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.rootDesktop}>
        <MenuDesktop />
      </div>
      <div className={classes.rootMobile}>
        <MenuMobile />
      </div>
    </>
  );
};

export default Menu;
