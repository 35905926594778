import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  infoPlanCard: {
    height: '390px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: `${theme.palette.primary.light} !important`,
    paddingBottom: '0rem !important',
    borderRadius: '15px !important',
    boxShadow:
      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
  },
  img: {
    objectPosition: 'center center',
    objectFit: 'contain',
    height: '139px',
  },
  column: {
    height: '20%',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '0px 16px',
  },
  name: {
    fontFamily: 'Roboto !important',
    fontWeight: '500 !important',
    fontSize: '18px !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    letterSpacing: '0.15px !important',
    lineHeight: '160% !important',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textAlign: 'left',
  },
  columnButton: {
    height: '18%',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '0px 16px',
    justifyContent: 'flex-start',
  },
  columnPriceType: {
    height: '7%',
    display: 'flex',
    width: '100%',
    padding: '0px 16px',
    textAlign: 'end',
    alignItems: 'center',
  },
  columnPrice: {
    height: '18%',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
}));
