import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiStepLabel-label.Mui-completed': {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& .MuiStepLabel-label.Mui-disabled': {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& .MuiStepIcon-root.Mui-active': {
      color: `${theme.palette.primary.main}`,
      border: 'unset',
      '& .MuiStepIcon-text': {
        fill: 'white',
      },
    },
    '& .MuiStepIcon-root.Mui-completed': {
      border: 'unset',
      color: `${theme.palette.primary.main}`,
      '& .MuiStepIcon-text': {
        fill: 'white',
      },
    },
    '& .MuiStepIcon-root': {
      border: `1px solid ${theme.palette.primary.main}`,
      color: 'white',
      borderRadius: '64px',
      '& .MuiStepIcon-text': {
        fill: `${theme.palette.primary.dark}`,
      },
    },
  },
}));
