import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  price: {
    fontFamily: 'Roboto !important',
    fontWeight: '500 !important',
    fontSize: '22px !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    letterSpacing: '0.15px !important',
    lineHeight: '0px !important',
    textAlign: 'left',
  },
  singlePayment: {
    fontFamily: 'Roboto !important',
    fontWeight: '400 !important',
    fontSize: '12px !important',
    color: 'rgba(0, 0, 0, 0.75) !important',
    letterSpacing: '0.15px !important',
    lineHeight: '166%',
    textAlign: 'left',
  },
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '0px 16px',
    gap: '8px',
  },
}));
