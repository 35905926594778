import { FC, useContext } from 'react';

import { ARCHETYPE_WITH_IMAGE, ARCHETYPE_WITHOUT_IMAGE } from '../../../../constants/constants';
import BusinessUnitParamsContext from '../../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import CardHirePlan from '../CardHirePlan';
import CardHirePlanWithImage from '../CardHirePlanWithImage';

type Props = {
  infoToContract: any;
};
const CardHirePlanWithArcheType: FC<Props> = ({ infoToContract }) => {
  const { portalArchetypeId } = useContext(BusinessUnitParamsContext);

  const getCardHireComponent = () => {
    switch (portalArchetypeId) {
      case ARCHETYPE_WITHOUT_IMAGE:
        return <CardHirePlan infoToContract={infoToContract} />;
      case ARCHETYPE_WITH_IMAGE:
        return <CardHirePlanWithImage infoToContract={infoToContract} />;
      default:
        return <CardHirePlan infoToContract={infoToContract} />;
    }
  };

  return getCardHireComponent();
};

export default CardHirePlanWithArcheType;
