import { Box, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';

import { PAYMENT_TYPE_ID_SINGLE_PAYMENT } from '../../../../../constants/constants';
import CardPlanAmountsSection from '../../../../CardPlan/CardPlanAmountsSection';
import { localStyles } from './styles';

type Props = {
  t: any;
  currentPlan: any;
  classes: any;
};

const PaymentContainer: FC<Props> = ({ t, classes, currentPlan }) => {
  const localClasses = localStyles();
  const theme = useTheme();
  const smallSize = useMediaQuery(theme.breakpoints.down('sm'));
  const midSize = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const isPaymentTypeUnique = currentPlan?.paymentTypeId === PAYMENT_TYPE_ID_SINGLE_PAYMENT;

  return (
    <div className={classes.paymentContainer}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={smallSize ? 12 : 6}
          className={localClasses.priceTitle}
          style={{ flexDirection: 'column' }}
        >
          <Typography className={classes.title}>{t('components.cardPlan.detail.price')}</Typography>
          <CardPlanAmountsSection item={currentPlan} detailModal={true} />
          {currentPlan?.paymentTypeId === 3 && (
            <Box marginTop={smallSize ? 0 : 1} paddingY={smallSize ? 1 : 0}>
              <Typography className={localClasses.ivaText}>
                {t('components.cardPlan.detail.ivaText')}
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid
          item
          xs={smallSize ? 12 : 1}
          className={smallSize ? localClasses.mobileDivider : localClasses.desktopDivider}
        >
          <Divider orientation={smallSize ? 'horizontal' : 'vertical'} />
        </Grid>
        <Grid
          item
          xs={isPaymentTypeUnique ? 5 : smallSize ? 12 : 5}
          className={localClasses.paymentTypeContainer}
          style={{ flexDirection: 'column' }}
        >
          <Typography className={classes.title}>
            {t('components.cardPlan.detail.paymentType')}
          </Typography>
          <Typography className={classes.subTitle}>{currentPlan?.paymentTypeName}</Typography>
        </Grid>
        {isPaymentTypeUnique && (
          <Grid
            item
            xs={smallSize ? 2 : 12}
            className={smallSize ? localClasses.desktopDivider : undefined}
          >
            <Divider orientation={smallSize ? 'vertical' : 'horizontal'} />
          </Grid>
        )}

        <Grid item xs={smallSize ? 5 : 12}>
          {isPaymentTypeUnique && (
            <Grid container>
              <Grid
                item
                xs={smallSize ? 12 : 1}
                className={
                  smallSize
                    ? localClasses.durationTitleMobileContainer
                    : localClasses.durationTitleDesktopContainer
                }
              >
                <Typography className={classes.title}>
                  {t('components.cardPlan.detail.duration')}
                </Typography>
              </Grid>
              <Grid
                item
                xs={smallSize ? 12 : 10}
                className={
                  smallSize
                    ? localClasses.durationPaymentMobileContainer
                    : midSize
                    ? localClasses.durationPaymentTabletContainer
                    : localClasses.durationPaymentContainer
                }
              >
                <Typography className={classes.subTitle}>{`${currentPlan.paymentDurationMonths} ${t(
                  'components.cardPlan.detail.duration.month'
                )}`}</Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default PaymentContainer;
