import { FC, useContext, useEffect, useState } from 'react';

import { getPlans } from '../../api/api';
import CardPlanWithArcheType from '../../components/CardPlanWithArcheType';
import BusinessUnitParamsContext from '../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { getArrayMobile, isSlicePlans, isSlicePlansMobile } from '../../util/commons';
import CardPlanMobileWithStepper from '../CardPlan/CardPlanMobileWithStepper';
import { useStyles } from './styles';

type Props = {
  sliceParam?: boolean;
  planId?: any;
  filterCategoryValue?: any;
  isMobileViewWithoutCarousel?: boolean;
};

const CardPlanList: FC<Props> = ({
  sliceParam,
  planId,
  filterCategoryValue,
  isMobileViewWithoutCarousel,
}) => {
  const classes = useStyles();
  const { contextUrl } = useContext(BusinessUnitParamsContext);
  const url: string = contextUrl!;
  const [plansOriginal, setPlansOriginal] = useState([]);
  const [plans, setPlans] = useState([]);
  const [plansMobile, setPlansMobile] = useState<any>([]);

  useEffect(() => {
    const init = async () => {
      try {
        const response = await getPlans(url, planId);
        if (response && response.plans && response.plans.length > 0) {
          setPlansOriginal(response.plans);
          setPlans(response.plans);

          const slicePlanReference = Math.ceil(response.plans.length / 6);
          setPlansMobile(getArrayMobile(slicePlanReference, response.plans));
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [planId]);

  useEffect(() => {
    if (filterCategoryValue) {
      const plansFilter = plansOriginal.filter((currentPlanCategory: any) => {
        return (
          currentPlanCategory.categories.find(
            (category: any) => category.categoryId == filterCategoryValue
          ) != undefined
        );
      });
      setPlans(plansFilter);
      const slicePlanReference = Math.ceil(plansFilter.length / 6);
      setPlansMobile(getArrayMobile(slicePlanReference, plansFilter));
    }

    if (filterCategoryValue == '') {
      setPlans(plansOriginal);
      const slicePlanReference = Math.ceil(plansOriginal.length / 6);
      setPlansMobile(getArrayMobile(slicePlanReference, plansOriginal));
    }
  }, [filterCategoryValue]);

  return (
    <>
      <div className={planId ? classes.cardContainerOnePlan : classes.cardContainer}>
        {isSlicePlans(sliceParam, plans).map((item: any, index: any) => (
          <CardPlanWithArcheType key={index} item={item} index={index} />
        ))}
      </div>

      {isMobileViewWithoutCarousel ? (
        <div className={classes.cardContainerMobileWithoutCarousel}>
          {plans.map((item: any, index: any) => (
            <CardPlanWithArcheType key={index} item={item} index={index} />
          ))}
        </div>
      ) : (
        isSlicePlansMobile(sliceParam, plansMobile).map(
          (currentPlanArray: any, indexSlice: any) => (
            <CardPlanMobileWithStepper
              key={indexSlice}
              indexSlice={`${indexSlice}-${plansMobile.length}-${currentPlanArray.length}`}
              maxSteps={currentPlanArray.length}
            >
              {currentPlanArray.map((item: any, index: any) => (
                <CardPlanWithArcheType key={`card-${index}`} item={item} index={index} />
              ))}
            </CardPlanMobileWithStepper>
          )
        )
      )}
    </>
  );
};

export default CardPlanList;
