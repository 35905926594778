import { Button, Typography } from '@mui/material';
import { FC, SyntheticEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { MAX_NUMBER_PLANS_DEFAULT } from '../../../constants/constants';
import { PLANS } from '../../../constants/routes';
import BusinessUnitParamsContext from '../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { useStyles } from './styles';

type Props = {
  planSize?: any;
};
const WelcomeSection: FC<Props> = ({ planSize }) => {
  const classes = useStyles();
  const history = useHistory();
  const { cover_description, cover_image_url, cover_title } = useContext(BusinessUnitParamsContext);
  const { t } = useTranslation();

  const goToPlans = (event: SyntheticEvent) => {
    event.preventDefault();
    history.push({ pathname: PLANS });
  };

  const showButtonPlans = () => {
    return planSize && planSize > 1 && planSize > MAX_NUMBER_PLANS_DEFAULT;
  };

  return (
    <div className={classes.root}>
      <div className={classes.leftColumn}>
        <Typography className={classes.title}>{cover_title}</Typography>
        <Typography className={classes.description}>{cover_description}</Typography>
        {showButtonPlans() && (
          <Button variant="contained" className={classes.button} onClick={goToPlans}>
            {t('pages.home.welcome.button.viewPlans')}
          </Button>
        )}
      </div>
      <div className={classes.rightColumn} style={{ backgroundImage: `url(${cover_image_url})` }} />
    </div>
  );
};

export default WelcomeSection;
