import { Grid, Typography } from '@mui/material';
import { FC, useContext } from 'react';

import BusinessUnitParamsContext from '../../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { formatPriceToLocaleAndCurrency } from '../../../../util/commons';
import { useStyles } from './styles';

type PaymentsFrequenciesItem = {
  price: string;
  frequency: string;
  order: number;
  paymentFrequencyName?: string;
};

type Props = {
  item?: {
    name?: string;
    description?: string;
    price?: string;
    categories?: Array<any>;
    id?: number;
    paymentsFrequencies?: Array<PaymentsFrequenciesItem>;
    paymentTypeName?: string;
    paymentDurationMonths?: string;
  };
  showInfo?: boolean;
};

const SinglePayment: FC<Props> = ({ item = {}, showInfo = true }) => {
  const classes = useStyles();
  const { thousandsSeparator, decimalsSeparator, currencyDecimalPlaces, currencySymbol } =
    useContext(BusinessUnitParamsContext);

  return (
    <>
      <Grid container xs={12} className={classes.container}>
        <Typography className={classes.price}>
          {formatPriceToLocaleAndCurrency(
            item.price ? item.price : '0',
            thousandsSeparator,
            decimalsSeparator,
            currencyDecimalPlaces,
            currencySymbol,
            true
          )}
        </Typography>
        {showInfo && (
          <Typography className={classes.singlePayment}>
            {`${item['paymentTypeName']} - duración: ${item['paymentDurationMonths']} meses`}
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default SinglePayment;
