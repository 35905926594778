import AddIcon from '@mui/icons-material/Add';
import { Alert, Button, Grid, Link, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SimpleAlert } from '../../../../components/atoms/SimpleAlert';
import {
  LifeInsuranceBeneficiaryInputs,
  LifeInsuranceBeneficiaryList,
} from '../../models/LifeInsuranceBeneficiaryInputs';
import InsuranceLifeFormElement from './InsuranceLifeFormElement';
import { useStyles } from './styles';

type StepProps = {
  control: Control<LifeInsuranceBeneficiaryList>;
  alert: boolean;
  showAlert: boolean;
  maximumBeneficiariesLifeInsurance: number;
  setValue: UseFormSetValue<LifeInsuranceBeneficiaryList>;
  watch: UseFormWatch<LifeInsuranceBeneficiaryList>;
  lifeInsuranceBeneficiaryAppend: any;
  lifeInsuranceBeneficiaryRemove: any;
  lifeInsuranceBeneficiaryList: any;
  percentageChange: any;
};

const InsuranceLifeFormStep = (props: StepProps) => {
  const {
    lifeInsuranceBeneficiaryList,
    lifeInsuranceBeneficiaryAppend,
    lifeInsuranceBeneficiaryRemove,
    maximumBeneficiariesLifeInsurance,
    control,
    alert,
    watch,
    setValue,
    percentageChange,
    showAlert,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    if (lifeInsuranceBeneficiaryList.length === 0) {
      lifeInsuranceBeneficiaryAppend({
        name: '',
        lastName: '',
        email: '',
        relationship: '',
      });
    }
  }, []);

  useEffect(() => {
    percentageChange();
  }, [lifeInsuranceBeneficiaryList]);

  const addLifeInsuranceBeneficiary = () => {
    lifeInsuranceBeneficiaryAppend({
      name: '',
      lastName: '',
      email: '',
      relationship: '',
    });
  };

  const getMessage = (maximumBeneficiariesLifeInsurance: number) => {
    return t('pages.hirePlan.beneficiary.alert.type1', {
      maximumBeneficiaries: maximumBeneficiariesLifeInsurance,
    });
  };

  const numberWithCommas = (x: number) => {
    return x.toString().replace(/\./g, ',');
  };

  const split = (last: boolean) => {
    const total = 100;
    const div = lifeInsuranceBeneficiaryList.length;
    if (last) {
      return numberWithCommas(+(total - +(total / div).toFixed(2) * (div - 1)).toFixed(2));
    }
    return numberWithCommas(+(total / div).toFixed(2));
  };

  const handleSplit = (e: any) => {
    e.preventDefault();
    lifeInsuranceBeneficiaryList.forEach(
      (
        currentLifeInsuranceBeneficiary: LifeInsuranceBeneficiaryInputs,
        indexLifeInsuranceBeneficiary: number
      ) => {
        setValue(
          `lifeInsuranceBeneficiaries.${indexLifeInsuranceBeneficiary}.percentage`,
          `${split(indexLifeInsuranceBeneficiary + 1 === lifeInsuranceBeneficiaryList.length)}`
        );
      }
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h6">{t('pages.hirePlan.lifeInsuranceBeneficiariy.title')}</Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography variant="body1">
          {t('pages.hirePlan.lifeInsuranceBeneficiariy.subTitle')}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.buttonContainer}>
        {lifeInsuranceBeneficiaryList.length > 1 && (
          <Link href="#" color="inherit" onClick={handleSplit} className={classes.link}>
            {t('pages.hirePlan.lifeInsuranceBeneficiariy.split.percentage.button')}
          </Link>
        )}
      </Grid>
      <Grid item xs={12}>
        {lifeInsuranceBeneficiaryList.map(
          (
            currentLifeInsuranceBeneficiary: LifeInsuranceBeneficiaryInputs,
            indexLifeInsuranceBeneficiary: number
          ) => {
            return (
              <InsuranceLifeFormElement
                key={currentLifeInsuranceBeneficiary.id}
                id={indexLifeInsuranceBeneficiary}
                control={control}
                alert={alert}
                watch={watch}
                lifeInsuranceBeneficiaryRemove={lifeInsuranceBeneficiaryRemove}
                percentageChange={percentageChange}
              />
            );
          }
        )}
      </Grid>
      {alert && (
        <Grid item xs={12} sm={12}>
          <SimpleAlert
            type={'error'}
            textTitle={t('pages.hirePlan.beneficiary.alert.title')}
            text={t('pages.hirePlan.beneficiary.alert.body')}
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={12} className={classes.endContainer}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            {getMessage(maximumBeneficiariesLifeInsurance) !== null && (
              <Alert severity="info" className={classes.alert}>
                {getMessage(maximumBeneficiariesLifeInsurance)}
              </Alert>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.containerButton}>
            <Button
              startIcon={<AddIcon />}
              variant="outlined"
              className={
                lifeInsuranceBeneficiaryList.length >= maximumBeneficiariesLifeInsurance
                  ? classes.default
                  : classes.button
              }
              onClick={addLifeInsuranceBeneficiary}
              disabled={lifeInsuranceBeneficiaryList.length >= maximumBeneficiariesLifeInsurance}
            >
              {t('pages.hirePlan.beneficiary.button.add')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {showAlert && (
          <Alert severity="warning" className={classes.alertWarning}>
            {t('pages.hirePlan.lifeInsuranceBeneficiariy.percentage.alert')}
          </Alert>
        )}
      </Grid>
    </Grid>
  );
};

export default InsuranceLifeFormStep;
