import { Grid, Typography } from '@mui/material';
import { FC, Fragment, useContext } from 'react';

import BusinessUnitParamsContext from '../../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { formatPriceToLocaleAndCurrency } from '../../../../util/commons';
import { useStyles } from './styles';

type PaymentsFrequenciesItem = {
  price: string;
  frequency: string;
  order: number;
  paymentFrequencyName?: string;
};

type Props = {
  item?: {
    name?: string;
    description?: string;
    price?: string;
    categories?: Array<any>;
    id?: number;
    paymentsFrequencies?: Array<PaymentsFrequenciesItem>;
  };
  numberSize?: number;
  setNumberSize: any;
};
const PlanAmountsSection: FC<Props> = ({ item = {}, numberSize = 0, setNumberSize }) => {
  const classes = useStyles();
  const { thousandsSeparator, decimalsSeparator, currencyDecimalPlaces, currencySymbol } =
    useContext(BusinessUnitParamsContext);

  const reverseOrder = (a: PaymentsFrequenciesItem, b: PaymentsFrequenciesItem): 0 | 1 | -1 => {
    if (a.order > b.order) {
      return -1;
    }

    if (a.order < b.order) {
      return 1;
    }

    return 0;
  };

  return (
    <Fragment key={item.id}>
      {item.price ? (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography className={classes.price}>
              {formatPriceToLocaleAndCurrency(
                item.price,
                thousandsSeparator,
                decimalsSeparator,
                currencyDecimalPlaces,
                currencySymbol,
                false
              )}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={0} className={classes.paymentRecurrentContainer}>
          {item.paymentsFrequencies &&
            item.paymentsFrequencies.sort(reverseOrder).map((paymentFrecuency, index) => {
              const isSetSeparator =
                index % 2 === 0 && item?.paymentsFrequencies?.[index + 1] !== undefined;

              const sizeNumberLength = isSetSeparator && paymentFrecuency.price.toString().length;

              if (numberSize < sizeNumberLength) {
                setNumberSize(sizeNumberLength);
              }

              return (
                <Fragment key={index + `${item?.id}`}>
                  <Grid item xs={6} className={classes.priceContainer}>
                    <Grid container className={classes.flexStartCenter}>
                      <Grid item xs={10} className={classes.flexStartCenter}>
                        <>
                          <Typography
                            className={classes.priceRecurrent}
                          >{`${formatPriceToLocaleAndCurrency(
                            paymentFrecuency.price,
                            thousandsSeparator,
                            decimalsSeparator,
                            currencyDecimalPlaces,
                            currencySymbol,
                            false
                          )}`}</Typography>
                          <Typography className={classes.priceFrecuency}>
                            {paymentFrecuency.paymentFrequencyName?.toLocaleLowerCase()}
                          </Typography>
                        </>
                      </Grid>
                      <Grid item xs={2} className={classes.separatorContainer}>
                        {isSetSeparator && <Typography className={classes.separator}>|</Typography>}
                      </Grid>
                    </Grid>
                  </Grid>
                </Fragment>
              );
            })}
        </Grid>
      )}
    </Fragment>
  );
};

export default PlanAmountsSection;
