import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Card, CardMedia, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { setCurrentPlanIdToShowDetailsAction } from '../../../../redux/actions/planActions';
import { useStylesCardGlobal } from '../../../../styles/global/cardStyles';
import { formatEllipsis } from '../../../../util/commons';
import CardPlanAmountsSection from '../CardHirePlanMobileWithImage/CardPlanAmountsSection';
import CardPlanAmountTypeSection from '../CardHirePlanMobileWithImage/CardPlanAmountTypeSection';
import { useStyles } from './styles';

interface RouteParams {
  id: string;
}
type Props = {
  infoToContract: any;
};
const CardHirePlanWithImage: FC<Props> = ({ infoToContract }) => {
  const classes = useStyles();
  const classesCardGlobal = useStylesCardGlobal();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams<RouteParams>();
  const theme = useTheme();
  const smallSize = useMediaQuery(theme.breakpoints.down('sm'));
  const id = Number(params.id);

  const handleDownload = () => {
    window.open(infoToContract?.infoPlan.urlPlan);
  };

  const goToDetails = () => {
    dispatch(setCurrentPlanIdToShowDetailsAction(Number(id)));
  };

  return (
    <Grid item xs={smallSize ? 12 : 4} className={classesCardGlobal.showDesktopPlanCard}>
      <Card className={classes.card}>
        <CardMedia
          component={'img'}
          alt={infoToContract?.infoPlan.businessPlanName}
          image={infoToContract?.infoPlan.imageUrl}
          classes={{
            media: classes.img,
          }}
        />
        <div className={classes.column}>
          <Typography className={classes.name}>
            {formatEllipsis(infoToContract?.infoPlan.businessPlanName, 70)}
          </Typography>
        </div>
        <div className={classes.columnButton}>
          <Button
            variant="outlined"
            className={classesCardGlobal.button}
            onClick={handleDownload}
            size={'small'}
          >
            {t('Descargar plan')}
          </Button>
        </div>
        <div className={classesCardGlobal.columnDescription}>
          <Typography className={classes.description}>
            {formatEllipsis(infoToContract?.infoPlan.businessPlanShortDescription, 190)}
          </Typography>
        </div>
        <div className={classes.columnPaymentType}>
          <CardPlanAmountTypeSection item={infoToContract?.infoPlan} />
        </div>
        <div className={classes.columnPrice}>
          <CardPlanAmountsSection item={infoToContract?.infoPlan} />
        </div>
        <div className={classes.columnDetail}>
          <Link className={classesCardGlobal.details} underline="none" onClick={goToDetails}>
            <div className={classesCardGlobal.elementLink}> {t('Ver detalle')}</div>
            <div className={classesCardGlobal.elementLink}>
              <ArrowForwardIcon className={classesCardGlobal.icon} />
            </div>
          </Link>
        </div>
      </Card>
    </Grid>
  );
};

export default CardHirePlanWithImage;
