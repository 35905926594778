import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import robotError from '../../../../assets/images/robot-error.svg';
import { Image } from '../../../../components/atoms/Image';
import { useStyles } from './styles';

type StepProps = {
  title?: string | null;
  body?: string | null;
  handleBack: any;
};

const ErrorScreen = (props: StepProps) => {
  const { title, body, handleBack } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} className={classes.center}>
      <Grid item xs={6} className={classes.center}>
        <Image src={robotError} width={'auto'} />
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          {title && (
            <Grid item xs={12} className={classes.title}>
              {title}
            </Grid>
          )}
          {body && (
            <Grid item xs={12} className={classes.body}>
              {body}
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={handleBack}
              sx={{ mr: 1 }}
              className={classes.color}
            >
              {t('pages.hirePlan.button.back')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ErrorScreen;
