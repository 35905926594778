import dayjs from 'dayjs';
import * as EmailValidator from 'email-validator';
import { TFunction } from 'react-i18next';

import {
  MAX_NUMBER_PLANS_DEFAULT,
  MAX_NUMBER_PLANS_MOBILE_BY_ROW_DEFAULT,
  MAX_NUMBER_PLANS_MOBILE_DEFAULT,
} from '../constants/constants';

type PaymentsFrequenciesItem = {
  price: string;
  frequency?: string;
  paymentFrequencyName?: string;
};

export function validateEmail(email: string) {
  return EmailValidator.validate(email);
}

export const convertDate = (date: string | Date | number, format = 'DD/MM/YYYY'): string => {
  return dayjs(date).format(format);
};

export const getArrayMobile = (numberToSlice: number, array: Array<object>) => {
  const arrayMobile: any[] = [];

  for (let i = 0; i < numberToSlice; i++) {
    const arrayPart = array.slice(
      i * MAX_NUMBER_PLANS_MOBILE_BY_ROW_DEFAULT,
      MAX_NUMBER_PLANS_MOBILE_BY_ROW_DEFAULT * (i + 1)
    );
    arrayMobile.push(arrayPart);
  }

  return arrayMobile;
};

export const isSlicePlans = (sliceParam: any, plans: any) => {
  if (sliceParam) {
    return plans.slice(0, MAX_NUMBER_PLANS_DEFAULT);
  } else {
    return plans;
  }
};

export const isSlicePlansMobile = (sliceParam: any, plans: any) => {
  if (sliceParam) {
    return plans.slice(0, MAX_NUMBER_PLANS_MOBILE_DEFAULT);
  } else {
    return plans;
  }
};

export const scrollTo = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};

export const AddSeparatorsToFloatNumber = (
  value: string,
  thousandsSeparator: string | undefined,
  decimalsSeparator: string | undefined
) => {
  const currentThousandsSeparator = thousandsSeparator ? thousandsSeparator : ',';
  const currentDecimalsSeparator = decimalsSeparator ? decimalsSeparator : '.';

  value += '';
  const x = value.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? currentDecimalsSeparator + x[1] : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + currentThousandsSeparator + '$2');
  }
  return x1 + x2;
};

export const convertPriceWithArguments = (
  value: string,
  thousandsSeparator: string | undefined,
  decimalsSeparator: string | undefined,
  currencyDecimalPlaces: string | undefined
) => {
  let valueFloatString;

  //PARSE WITH DECIMALSPLACES
  valueFloatString = currencyDecimalPlaces
    ? parseFloat(value).toFixed(parseInt(currencyDecimalPlaces))
    : value;

  valueFloatString = AddSeparatorsToFloatNumber(
    valueFloatString,
    thousandsSeparator,
    decimalsSeparator
  );

  return valueFloatString;
};

export const formatPriceToLocaleAndCurrency = (
  value: string,
  thousandsSeparator: string | undefined,
  decimalsSeparator: string | undefined,
  currencyDecimalPlaces: string | undefined,
  currencySymbol: string | undefined,
  withSpace: boolean
) => {
  const valueParse = convertPriceWithArguments(
    value,
    thousandsSeparator,
    decimalsSeparator,
    currencyDecimalPlaces
  );

  if (withSpace) {
    return `${currencySymbol} ${valueParse}`;
  } else {
    return `${currencySymbol}${valueParse}`;
  }
};

export const formatEllipsis = (text: string | undefined, n: number) => {
  if (typeof text === 'undefined') {
    return '';
  }
  return text.length > n ? text.slice(0, n) + '...' : text;
};

export const formatPaymentFrenquency = (
  paymentsFrequencies: Array<PaymentsFrequenciesItem> | undefined,
  t: TFunction<'translation', undefined>,
  thousandsSeparator: string | undefined,
  decimalsSeparator: string | undefined,
  currencyDecimalPlaces: string | undefined,
  currencySymbol: string | undefined
) => {
  if (typeof paymentsFrequencies === 'undefined') {
    return '';
  }
  let response: string | null = null;

  let paymentsFrequenciesArray;

  if (
    paymentsFrequencies[0].frequency?.toLocaleLowerCase() === 'anual' ||
    paymentsFrequencies[0].paymentFrequencyName?.toLocaleLowerCase() === 'anual'
  ) {
    paymentsFrequenciesArray = [...paymentsFrequencies].reverse();
  } else {
    paymentsFrequenciesArray = [...paymentsFrequencies];
  }

  paymentsFrequenciesArray.forEach((element: PaymentsFrequenciesItem) => {
    if (response === null) {
      response = `${formatPriceToLocaleAndCurrency(
        element.price,
        thousandsSeparator,
        decimalsSeparator,
        currencyDecimalPlaces,
        currencySymbol,
        false
      )} ${
        element.frequency
          ? element.frequency?.toLocaleLowerCase()
          : element.paymentFrequencyName?.toLocaleLowerCase()
      }`;
    } else {
      response = t('components.paymentFrenquency.or.label', {
        firstElement: response,
        secondElemenet: `${formatPriceToLocaleAndCurrency(
          element.price,
          thousandsSeparator,
          decimalsSeparator,
          currencyDecimalPlaces,
          currencySymbol,
          false
        )} ${
          element.frequency
            ? element.frequency?.toLocaleLowerCase()
            : element.paymentFrequencyName?.toLocaleLowerCase()
        }`,
      });
    }
  });

  return response;
};

export const createScriptGoogleTagManager = (
  window: any,
  document: any,
  tagName: any,
  l: any,
  googleTagManagerCode: any,
  position: any
) => {
  window[l] = window[l] || [];
  window[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

  const f = document.getElementsByTagName(tagName)[position],
    documentTagNew = document.createElement(tagName),
    dl = l != 'dataLayer' ? '&l=' + l : '';

  documentTagNew.async = true;
  documentTagNew.src = 'https://www.googletagmanager.com/gtm.js?id=' + googleTagManagerCode + dl;
  f.parentNode.insertBefore(documentTagNew, f);
};
