import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  checkboxStyle: {
    color: `${theme.palette.primary.main} !important`,
  },
  containerCheck: {
    paddingLeft: '15px !important',
  },
  planLink: {
    color: `${theme.palette.primary.main} !important`,
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
    },
  },
  containerPlanLink: {
    paddingBottom: 20,
  },
  label: {
    fontFamily: 'Roboto !important',
    fontSize: '14px !important',
    fontWeight: '400 !important',
    lineHeight: '20px !important',
    letterSpacing: '0.15000000596046448px !important',
    textAlign: 'left',
    display: 'contents',
    paddingRight: '4px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px !important',
    },
  },
  asterisk: {
    color: '#F50057',
    fontFamily: 'Roboto !important',
    fontSize: '14px !important',
    fontWeight: '400 !important',
    display: 'contents',
    paddingRight: '4px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px !important',
    },
  },
  link: {
    color: `${theme.palette.primary.main} !important`,
  },
  elementCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  boldElement: {
    fontWeight: 500,
  },
}));
