import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontFamily: 'Roboto',
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '35px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  body: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'left',
    margin: '18px 0px !important',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  color: {
    background: `${theme.palette.primary.main} !important`,
    borderRadius: '10px !important',
  },
}));
