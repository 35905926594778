import { Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PAYMENT_TYPE_ID_PAYROLL,
  PAYMENT_TYPE_ID_RECURRENT,
  PAYMENT_TYPE_ID_SINGLE_PAYMENT,
} from '../../../../../constants/constants';
import { useStyles } from './styles';

type PaymentsFrequenciesItem = {
  price: string;
  frequency: string;
  order: number;
  paymentFrequencyName?: string;
};

type Props = {
  item?: {
    name?: string;
    description?: string;
    price?: string;
    categories?: Array<any>;
    id?: number;
    paymentsFrequencies?: Array<PaymentsFrequenciesItem>;
    paymentTypeId?: number;
    paymentDurationMonths?: number;
  };
  detailModal?: boolean;
};

const CardPlanAmountTypeSection: FC<Props> = ({ item = {} }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getAmountComponent = () => {
    switch (item.paymentTypeId) {
      case PAYMENT_TYPE_ID_PAYROLL:
        return <Typography className={classes.paymentType}>{t(`Tipo de pago: nómina`)}</Typography>;
      case PAYMENT_TYPE_ID_SINGLE_PAYMENT:
        return (
          <Typography className={classes.paymentType}>
            {t(`Tipo de pago: único - duración ${item.paymentDurationMonths} meses`)}
          </Typography>
        );
      case PAYMENT_TYPE_ID_RECURRENT:
        return (
          <Typography className={classes.paymentType}>{t(`Tipo de pago: recurrente`)}</Typography>
        );
      default:
        return null;
    }
  };

  return getAmountComponent();
};

export default CardPlanAmountTypeSection;
