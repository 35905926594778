import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(370px, 1fr))',
    gap: '40px 20px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, minmax(300px, 1fr))',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  cardContainerMobileWithoutCarousel: {
    display: 'grid',
    gap: '40px',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  cardContainerOnePlan: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));
