import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow:
      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    borderRadius: '15px !important',
    padding: '24px',
    display: 'flex',
    background: '#FAFAFA',
    flexDirection: 'column',
  },
  cardContainerWithoutPadding: {
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow:
      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    borderRadius: '15px !important',
    display: 'flex',
    background: '#FAFAFA',
    flexDirection: 'column',
  },
  paymentContainer: {
    background: '#FFFFFF',
    borderRadius: '15px !important',
    display: 'grid',
    gap: 15,
    padding: 16,
  },
  dividerSection: {
    width: '3px',
    background: theme.palette.primary.light,
    margin: '0px 15px',
  },
  title: {
    fontFamily: 'Roboto !important',
    fontSize: '18px !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    letterSpacing: '0.15px !important',
    lineHeight: '28.8px !important',
    fontWeight: '500 !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px !important',
    },
  },
  section: {
    width: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  price: {
    fontSize: '16px !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    letterSpacing: '0.15px !important',
    maxWidth: '225px',
    textAlign: 'center',
    fontFamily: 'Roboto !important',
    fontWeight: '400 !important',
    lineHeight: '24px !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
    },
  },
  chip: {
    backgroundColor: `${theme.palette.primary.light} !important`,
    borderRadius: '10px !important',
    fontWeight: '400 !important',
  },
  button: {
    fontFamily: 'Roboto !important',
    fontWeight: '500 !important',
    lineHeight: '26px !important',
    width: '250px',
    height: '36px',
    borderRadius: '10px !important',
    border: `1px solid ${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.main} !important`,
    fontSize: '14px !important',
    letterSpacing: '0.46px !important',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      display: 'none !important',
    },
  },
  mainTitle: {
    fontSize: '34px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px !important',
    },
  },
  contentContainer: {
    display: 'grid',
    width: '100%',
    gap: 20,
    [theme.breakpoints.down('sm')]: {
      gap: 15,
    },
  },
  contentContainerWithPadding: {
    display: 'grid',
    width: '100%',
    gap: 20,
    padding: '24px 24px 41px',
    [theme.breakpoints.down('sm')]: {
      gap: 15,
    },
  },
  subTitleContainer: {
    display: 'inline-flex',
  },
  paymentContainerSection: {
    display: 'flex',
  },
  frequency: {
    paddingTop: '8px',
    display: 'inline-flex',
    alignItems: 'center',
    borderTop: `2px solid ${theme.palette.primary.light}`,
    gap: 10,
  },
  item: {
    border: `2px solid ${theme.palette.primary.light}`,
    marginBottom: '2px !important',
    borderRadius: '15px !important',
    '& .Mui-expanded': {
      borderTopLeftRadius: '13px',
      borderTopRightRadius: '13px',
      borderBottomRightRadius: '0px',
      backgroundColor: `${theme.palette.primary.light} !important`,
      borderBottomLeftRadius: '0px',
    },
  },
  subTitle: {
    fontFamily: 'Roboto !important',
    fontSize: '14px !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    letterSpacing: '0.15px !important',
    fontWeight: '400 !important',
    lineHeight: '24px !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
    },
  },
  coverageDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 16px 16px',
    gap: '5px',
    background: '#FAFAFA',
    borderRadius: '4px',
    alignItems: 'flex-start',
  },
  grow: {
    flexGrow: '1',
  },
  buttonQuote: {
    fontFamily: 'Roboto !important',
    fontWeight: '500 !important',
    fontSize: '14px !important',
    letterSpacing: '0.46px !important',
    lineHeight: '26px !important',
    width: '258px',
    height: '42px',
    borderRadius: '10px !important',
    backgroundColor: `${theme.palette.primary.main} !important`,
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
  },
  buttonQuoteContainer: {
    fontSize: '34px',
    textAlign: 'center',
    marginTop: '15px !important',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      display: 'none !important',
    },
  },
  buttonQuoteContainerMobile: {
    fontSize: '34px',
    textAlign: 'center',
    marginTop: '15px !important',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none !important',
    },
  },
  buttonMobile: {
    fontFamily: 'Roboto !important',
    border: `1px solid ${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.main} !important`,
    fontWeight: '500 !important',
    fontSize: '14px !important',
    letterSpacing: '0.46px !important',
    lineHeight: '26px !important',
    width: '250px',
    height: '36px',
    borderRadius: '10px !important',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none !important',
    },
  },
  text: {
    color: 'rgba(0, 0, 0, 0.87) !important',
    fontSize: 16,
  },
  coverageContainer: {
    marginTop: '20px',
    maxHeight: '637px',
    width: '100%',
    display: 'grid',
    gap: '20px',
    overflowY: 'auto',
    paddingRight: '10px',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-scrollbar-thumb': {
      background: '#000000',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
    [theme.breakpoints.down('sm')]: {
      gap: 10,
    },
  },
  coverage: {
    gap: '10px',
    display: 'grid',
  },
}));
