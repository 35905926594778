import axios from 'axios';

export default axios.create({
  baseURL: process.env.REACT_APP_URL_ECOMMERCE_SALES_PORTAL,
  headers: { 'Content-type': 'application/json' },
});

export const getExistsFooterLinks = (url: string) => {
  return axios
    .get(
      `${process.env.REACT_APP_URL_ECOMMERCE_SALES_PORTAL}/sales-portal/exist-tc-legalnotice-question?url=${url}`
    )
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export const getTermsConditions = (url: string) => {
  return axios
    .get(
      `${process.env.REACT_APP_URL_ECOMMERCE_SALES_PORTAL}/sales-portal/termsConditions?url=${url}`
    )
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export const getLegalNotices = (url: string) => {
  return axios
    .get(`${process.env.REACT_APP_URL_ECOMMERCE_SALES_PORTAL}/sales-portal/legalNotices?url=${url}`)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export const getFrequentQuestions = (url: string) => {
  return axios
    .get(`${process.env.REACT_APP_URL_ECOMMERCE_SALES_PORTAL}/sales-portal/question?url=${url}`)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export const getPlans = (url: string, id?: any) => {
  const params = { url: url, id: id };

  return axios
    .get(`${process.env.REACT_APP_URL_ECOMMERCE_SALES_PORTAL}/sales-portal/plans`, { params })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export const getPlanById = (url: string, id: number) => {
  const params = { url: url, id: id };

  return axios
    .get(`${process.env.REACT_APP_URL_ECOMMERCE_SALES_PORTAL}/sales-portal/plan-by-id`, { params })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export const getCategories = (url: string) => {
  const params = { url: url };

  return axios
    .get(`${process.env.REACT_APP_URL_ECOMMERCE_SALES_PORTAL}/sales-portal/categories`, { params })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export const getCategoriesWithPlans = (url: string) => {
  const params = { url: url };

  return axios
    .get(
      `${process.env.REACT_APP_URL_ECOMMERCE_SALES_PORTAL}/sales-portal/plans-groupby-categories`,
      { params }
    )
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export const sendContactInformation = (url: string, body: object) => {
  return axios
    .post(
      `${process.env.REACT_APP_URL_ECOMMERCE_SALES_PORTAL}/sales-portal/send-contact-information?url=${url}`,
      body
    )
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export const getInformationForContractingPlan = (url: string, id: number) => {
  const params = { url: url, id: id };

  return axios
    .get(
      `${process.env.REACT_APP_URL_ECOMMERCE_SALES_PORTAL}/sales-portal/get-information-for-contracting-plan-flow`,
      { params }
    )
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export const createPlanSold = (body: object) => {
  return axios
    .post(`${process.env.REACT_APP_URL_ECOMMERCE_SALES_PORTAL}/sales-portal/plan-sold`, body)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export const createPlanSoldAuthorizeRenewal = (planSoldUUID: string, authorizeRenewal: boolean) => {
  return axios
    .post(
      `${process.env.REACT_APP_URL_ECOMMERCE_SALES_PORTAL}/sales-portal/plan-sold/authorize-renewal?planSoldUUID=${planSoldUUID}&authorizeRenewal=${authorizeRenewal}`,
      {}
    )
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export const getCommunesByRegionID = (id: number, businessUnitUUID: string | undefined) => {
  const params = { businessUnitUUID: businessUnitUUID };

  return axios
    .get(`${process.env.REACT_APP_URL_ECOMMERCE_SALES_PORTAL}/sales-portal/communes/${id}`, {
      params,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export const createPayrollSubscription = (body: object, businessUnitUUID: string | undefined) => {
  const headers = { businessunituuid: businessUnitUUID };
  return axios
    .post(
      `${process.env.REACT_APP_URL_ECOMMERCE_SALES_PORTAL}/sales-portal/create-subscription-payroll`,
      body,
      { headers: headers }
    )
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export const updatePlanSold = (body: object, planSoldUUID: string) => {
  const params = { planSoldUUID: planSoldUUID };
  return axios
    .put(`${process.env.REACT_APP_URL_ECOMMERCE_SALES_PORTAL}/sales-portal/plan-sold`, body, {
      params,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export const getTermsDataPrivacy = (url: string) => {
  return axios
    .get(
      `${process.env.REACT_APP_URL_ECOMMERCE_SALES_PORTAL}/sales-portal/termsDataPrivacy?url=${url}`
    )
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};
