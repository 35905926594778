import { Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getPlans } from '../../api/api';
import CardPlanDetailsDialog from '../../components/CardPlan/CardPlanDetailsDialog';
import CardPlanList from '../../components/CardPlanList';
import CustomBreadcumbs from '../../components/CustomBreadcumbs';
import ContainerPage from '../../components/Layout/ContainerPage';
import SelectCategories from '../../components/Layout/SelectCategories';
import { MAX_NUMBER_PLANS_DEFAULT } from '../../constants/constants';
import { PLANS } from '../../constants/routes';
import { PLANS_VIEW_NAME } from '../../constants/views';
import BusinessUnitParamsContext from '../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { setCurrentViewLink, setCurrentViewName } from '../../redux/actions/appActions';
import { scrollTo } from '../../util/commons';
import { useStyles } from './styles';

const Plans = () => {
  const dispatch = useDispatch();
  const { contextUrl } = useContext(BusinessUnitParamsContext);
  const url: string = contextUrl!;
  const classes = useStyles();
  const [filterCategoryValue, setFilterCategoryValue] = useState('');
  const [planSize, setPlanSize] = useState(0);

  useEffect(() => {
    scrollTo();
    dispatch(setCurrentViewName({ currentViewName: PLANS_VIEW_NAME }));
    dispatch(setCurrentViewLink({ currentViewLink: PLANS }));

    const init = async () => {
      try {
        const response = await getPlans(url);
        if (response && response.plans && response.plans.length > 0) {
          setPlanSize(response.plans.length);
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, []);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterCategoryValue(event.target.value);
  };

  return (
    <ContainerPage>
      <CardPlanDetailsDialog />
      <CustomBreadcumbs />
      {planSize > MAX_NUMBER_PLANS_DEFAULT && (
        <Grid container className={classes.container}>
          <Grid item md={9} sm={12} xs={12}></Grid>
          <Grid item md={3} sm={12} xs={12}>
            <SelectCategories value={filterCategoryValue} onChange={onChange} />
          </Grid>
        </Grid>
      )}
      <CardPlanList filterCategoryValue={filterCategoryValue} isMobileViewWithoutCarousel />
    </ContainerPage>
  );
};

export default Plans;
