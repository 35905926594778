import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#FFFFFF',
  },
  leftBox: {
    display: 'flex',
    backgroundColor: theme.palette.primary.light,
    height: '12.48rem',
    borderRadius: '0px 120px 120px 0px',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: '0rem 4.75rem 0rem 4.125rem',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0rem 2.5rem 0rem 1.5rem',
    },
  },
  rightBox: {
    display: 'flex',
    height: '12.48rem',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'end',
      padding: '2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      padding: '1rem',
    },
    '& .MuiGrid-container': {
      display: 'flex',
      alignItems: 'end',
      marginBottom: '1.5rem',
    },
  },
  portalNameBox: {
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down('sm')]: {
      visibility: 'hidden',
      height: '0rem',
    },
  },
  portalName: {
    fontSize: '18px !important',
    fontWeight: '500 !important',
  },
  navigationText: {
    fontSize: '14px !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    fontFamily: 'Roboto',
  },
  logo: {
    cursor: 'pointer',
    maxHeight: '3.125rem',
    maxWidth: '-webkit-fill-available',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '3.125rem',
    },
  },
  logoBox: {
    display: 'flex',
    justifyContent: 'end',
  },
}));
