import { Divider, Link } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getCategoriesWithPlans } from '../../../../../api/api';
import { CONTACT_US } from '../../../../../constants/routes';
import BusinessUnitParamsContext from '../../../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { useStyles } from '../../../../../styles/global/stylesMenuGlobal';
import { localUseStyles } from './styles';

type Props = {
  handleClose?: any;
  anchorEl?: any;
  open?: any;
};

const MenuContent: FC<Props> = ({ handleClose, anchorEl, open }) => {
  const classes = useStyles();
  const localClasses = localUseStyles();
  const [categoriesMenu, setCategoriesMenu] = useState<any>([]);
  const { contextUrl } = useContext(BusinessUnitParamsContext);
  const url: string = contextUrl!;
  const { t } = useTranslation();

  useEffect(() => {
    const init = async () => {
      try {
        const response = await getCategoriesWithPlans(url);
        if (response.categories) setCategoriesMenu(response.categories);
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, []);

  const goToContact = () => {
    window.location.replace(CONTACT_US);
  };

  const goToCategory = (id: any) => {
    handleClose();
    window.location.replace(`/category?id=${id}`);
  };

  const goToPlan = (id: any) => {
    handleClose();
    window.location.replace(`/plan?id=${id}`);
  };

  function compareCategoriesNames(): any {
    return (a: any, b: any) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      return 0;
    };
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      classes={{ paper: classes.menuPaper }}
    >
      <Typography gutterBottom className={classes.title}>
        {t('components.layout.header.menuContent.hirePlan')}
      </Typography>
      <Divider className={classes.divider} />
      {categoriesMenu.sort(compareCategoriesNames()).map((item: any, index: any) => (
        <MenuList key={`menu-list-${index}`} classes={{ root: classes.menuListRoot }}>
          <MenuItem
            onClick={() => {
              goToCategory(item.id);
            }}
            disableRipple
            classes={{ root: classes.menuItemRoot }}
            style={{ whiteSpace: 'normal' }}
          >
            <ListItemText primary={item.name} classes={{ root: classes.category }} />
          </MenuItem>
          {item.plans &&
            item.plans.map((itemPlan: any, indexPlan: any) => (
              <MenuItem
                key={`plan-${index}-${indexPlan}`}
                onClick={() => {
                  goToPlan(itemPlan.planId);
                }}
                disableRipple
                classes={{ root: classes.menuItemPlanRoot }}
                style={{ whiteSpace: 'normal' }}
              >
                <ListItemText primary={itemPlan.planName} classes={{ root: classes.plan }} />
              </MenuItem>
            ))}
          {item.hasMorePlans && (
            <Link
              component="button"
              underline="hover"
              onClick={() => {
                goToCategory(item.id);
              }}
              classes={{ root: localClasses.viewMoreLink }}
            >
              {t('components.layout.header.menuContent.viewMore')}
            </Link>
          )}
        </MenuList>
      ))}
      <MenuItem
        onClick={goToContact}
        disableRipple
        classes={{ root: classNames(classes.menuItemRoot, classes.menuItemMobileRoot) }}
      >
        <ListItemText
          primary={t('components.layout.header.menuContent.contactUs')}
          classes={{ root: classes.category }}
        />
      </MenuItem>
    </Popover>
  );
};

export default MenuContent;
