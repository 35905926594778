import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from '@mui/material';
import { SyntheticEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CONTACT_US } from '../../../../../constants/routes';
import BusinessUnitParamsContext from '../../../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { useStyles } from '../../../../../styles/global/stylesMenuGlobal';
import MenuContent from '../MenuContent';

const MenuDesktop = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const { plan_count } = useContext(BusinessUnitParamsContext);

  const goToContact = () => {
    window.location.replace(CONTACT_US);
  };

  const handleClick = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Link underline="none" className={classes.link} onClick={goToContact}>
        {t('components.layout.header.menuDesktop.contactUs')}
      </Link>
      {plan_count && plan_count > 1 && (
        <Link underline="none" className={classes.link} onClick={handleClick}>
          {t('components.layout.header.menuDesktop.hirePlan')}
          <KeyboardArrowDownIcon />
        </Link>
      )}
      <MenuContent handleClose={handleClose} anchorEl={anchorEl} open={open} />
    </>
  );
};

export default MenuDesktop;
