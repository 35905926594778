import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';

import { StepObject } from './models/StepObject';
import { useStyles } from './styles';

type HirePlanProps = {
  steps?: Array<StepObject>;
  activeStep: number;
} & typeof defaultProps;

const defaultProps = {
  steps: [
    { id: 1, text: 'Step 1', optional: false, visible: true },
    { id: 2, text: 'Step 2', optional: false, visible: true },
    { id: 3, text: 'Step 3', optional: false, visible: true },
  ],
};

const HirePlan = (props: HirePlanProps) => {
  const { steps, activeStep } = props;
  const classes = useStyles();

  const isStepOptional = (optional: boolean) => {
    return optional === true;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map(({ text, optional, visible }, label) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: ReactNode;
          } = {};
          if (isStepOptional(optional)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          return (
            visible && (
              <Step key={label} {...stepProps} className={classes.root}>
                <StepLabel {...labelProps}>{text}</StepLabel>
              </Step>
            )
          );
        })}
      </Stepper>
    </Box>
  );
};

HirePlan.defaultProps = defaultProps;

export default HirePlan;
