import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import BusinessUnitParamsContext from '../../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { formatPaymentFrenquency, formatPriceToLocaleAndCurrency } from '../../../../util/commons';
import { PAYMENT_TYPE_RECURRENT } from '../../constants';
import { useStyles } from './styles';

type Props = {
  infoToContract: any;
};
const PlanPrices: FC<Props> = ({ infoToContract }) => {
  const { thousandsSeparator, decimalsSeparator, currencyDecimalPlaces, currencySymbol } =
    useContext(BusinessUnitParamsContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const smallSize = useMediaQuery(theme.breakpoints.down('sm'));

  const priceToFormat = infoToContract?.infoPlan.price
    ? infoToContract?.infoPlan.price
    : infoToContract?.infoPlan.paymentsFrequencies[0].price;

  const isMultiPaymentStyles =
    infoToContract?.infoPlan &&
    !infoToContract?.infoPlan.price &&
    infoToContract?.infoPlan.paymentsFrequencies.length >= 1;

  const price =
    infoToContract?.infoPlan.price || infoToContract?.infoPlan.paymentsFrequencies.length === 1
      ? priceToFormat == undefined
        ? ''
        : formatPriceToLocaleAndCurrency(
            priceToFormat,
            thousandsSeparator,
            decimalsSeparator,
            currencyDecimalPlaces,
            currencySymbol,
            false
          )
      : formatPaymentFrenquency(
          infoToContract?.infoPlan.paymentsFrequencies,
          t,
          thousandsSeparator,
          decimalsSeparator,
          currencyDecimalPlaces,
          currencySymbol
        );

  const reverseOrder = (a: any, b: any): 0 | 1 | -1 => {
    if (a.order > b.order) {
      return -1;
    }
    if (a.order < b.order) {
      return 1;
    }
    return 0;
  };

  return (
    <Grid container columnSpacing={1} className={classes.pricesContainer}>
      {infoToContract?.infoPlan?.paymentTypeId === PAYMENT_TYPE_RECURRENT ? (
        infoToContract?.infoPlan.paymentsFrequencies
          .sort(reverseOrder)
          .map((paymentFrequency: any, index: any) => {
            return (
              <>
                <Grid item xs={smallSize ? 6 : 7} className={classes.priceRecurrentAmountContainer}>
                  <Typography
                    key={index}
                    className={isMultiPaymentStyles ? classes.planMultiAmount : classes.planAmount}
                    variant="h3"
                  >
                    {formatPriceToLocaleAndCurrency(
                      paymentFrequency.price,
                      thousandsSeparator,
                      decimalsSeparator,
                      currencyDecimalPlaces,
                      currencySymbol,
                      false
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={smallSize ? 6 : 5}
                  className={classes.priceRecurrentFrecuencyContainer}
                >
                  <Typography
                    key={index}
                    className={
                      isMultiPaymentStyles ? classes.planMultiFrequency : classes.planAmount
                    }
                    variant="h3"
                  >
                    {paymentFrequency.frequency?.toLocaleLowerCase()}
                  </Typography>
                </Grid>
              </>
            );
          })
      ) : (
        <Grid item xs={12} className={classes.pricesAmountContainer}>
          <Typography
            className={isMultiPaymentStyles ? classes.planMultiAmount : classes.planAmount}
            variant="h3"
          >
            {price}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default PlanPrices;
