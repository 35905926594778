import { Grid, Typography } from '@mui/material';
import { FC, useContext } from 'react';

import BusinessUnitParamsContext from '../../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { formatPriceToLocaleAndCurrency } from '../../../../util/commons';
import { useStyles } from './styles';

type PaymentsFrequenciesItem = {
  price: string;
  frequency: string;
  order: number;
  paymentFrequencyName?: string;
};

type Props = {
  item?: {
    name?: string;
    description?: string;
    price?: string;
    categories?: Array<any>;
    id?: number;
    paymentsFrequencies?: Array<PaymentsFrequenciesItem>;
  };
  detailModal?: boolean;
};

const PayrollPayment: FC<Props> = ({ item = {} }) => {
  const classes = useStyles();
  const { thousandsSeparator, decimalsSeparator, currencyDecimalPlaces, currencySymbol } =
    useContext(BusinessUnitParamsContext);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography className={classes.price}>
            {formatPriceToLocaleAndCurrency(
              item.price ? item.price : '0',
              thousandsSeparator,
              decimalsSeparator,
              currencyDecimalPlaces,
              currencySymbol,
              true
            )}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default PayrollPayment;
