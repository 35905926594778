import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const localStyles = makeStyles((theme: Theme) => ({
  priceTitle: {
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
  },
  ivaText: {
    fontSize: '12px !important',
    color: 'rgba(117, 117, 117, 1) !important',
  },
  mobileDivider: {
    display: 'grid !important',
    alignItems: 'center !important',
  },
  desktopDivider: {
    display: 'grid !important',
    justifyContent: 'center !important',
  },
  paymentTypeContainer: {
    display: 'flex !important',
    justifyContent: 'start !important',
    alignItems: 'center !important',
  },
  durationTitleMobileContainer: {
    display: 'flex !important',
    justifyContent: 'start !important',
  },
  durationTitleDesktopContainer: {
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
  },
  durationPaymentMobileContainer: {
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'start !important',
  },
  durationPaymentContainer: {
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'start !important',
    padding: '0px 5px !important',
  },
  durationPaymentTabletContainer: {
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'start !important',
    padding: '0px 15px !important',
  },
  defaultRichText: {
    '& *': {
      fontSize: '14px !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px !important',
      },
    },
  },
}));
