import { Checkbox, FormControlLabel, Grid, Link, Typography } from '@mui/material';
import classNames from 'classnames';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import almostDone from '../../../../assets/images/almost-done.svg';
import check from '../../../../assets/images/check.svg';
import { Image } from '../../../../components/atoms/Image';
import BusinessUnitParamsContext from '../../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { PaymentsFrequency } from '../../../../models/HirePlanModel';
import { getDataPrivacyTermsSelector } from '../../../../redux/selectors/index';
import { formatPriceToLocaleAndCurrency } from '../../../../util/commons';
import { useStyles } from './styles';

type StepProps = {
  name: string;
  description: string;
  paymentTypeId: number;
  paymentTypeName: string;
  duration: number;
  frequency: string;
  price: string;
  confirmInfoPlan: boolean;
  authorizeRenewal: boolean;
  payrollEndWizard: boolean;
  notPayrollEndWizard: boolean;
  paymentsFrequency: PaymentsFrequency | null;
  onChangeConfirmInfoPlan: any;
  onChangeAuthorizeRenewal: any;
  handleDownload: any;
};

const ResumeAndPaymentStep = (props: StepProps) => {
  const {
    name,
    description,
    paymentTypeName,
    paymentTypeId,
    price,
    duration,
    frequency,
    confirmInfoPlan,
    authorizeRenewal,
    payrollEndWizard,
    notPayrollEndWizard,
    onChangeConfirmInfoPlan,
    onChangeAuthorizeRenewal,
    handleDownload,
    paymentsFrequency,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { dataPrivacyTerms } = useSelector(getDataPrivacyTermsSelector);
  const { thousandsSeparator, decimalsSeparator, currencyDecimalPlaces, currencySymbol } =
    useContext(BusinessUnitParamsContext);

  const getLabelAuthorizeRenewal = () => {
    return (
      <Typography className={classes.label}>
        {t('Autorizo pago y renovación automática.')}
      </Typography>
    );
  };

  const getCheckboxLabel = () => {
    if (dataPrivacyTerms === null) {
      return (
        <Typography className={classes.label}>
          {t('pages.hirePlan.resumeAndPayment.payroll.confirm.checkbox')}
        </Typography>
      );
    } else {
      return (
        <div>
          <Typography className={classes.label}>{t('Autorizo los ')}</Typography>
          <Link
            underline="none"
            className={classNames(classes.label, classes.link)}
            href={'/dataPrivacyTerms'}
            target={'_blank'}
          >
            {t('Términos de Tratamiento de Data Personal ')}
          </Link>
          <Typography className={classes.label}>
            {t('y confirmo que la información del plan que deseo contratar es correcta. ')}
          </Typography>
          <Typography className={classes.asterisk}>{t('*')}</Typography>
        </div>
      );
    }
  };

  if (notPayrollEndWizard) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} className={classes.elementCenter}>
          <Image src={almostDone} width={'auto'} />
        </Grid>
        <Grid item xs={12} className={classes.elementCenter}>
          <Typography variant="h6" align="center" sx={{ maxWidth: '50%' }}>
            {t('pages.hirePlan.resumeAndPayment.notPayroll.success.title')}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.elementCenter}>
          {t('pages.hirePlan.resumeAndPayment.notPayroll.success.subTitle')}
        </Grid>
      </Grid>
    );
  }

  if (payrollEndWizard) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} className={classes.elementCenter}>
          <Image src={check} width={'auto'} />
        </Grid>
        <Grid item xs={12} className={classes.elementCenter}>
          <Typography variant="h4">
            {t('pages.hirePlan.resumeAndPayment.payroll.success.title')}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.elementCenter}>
          {t('pages.hirePlan.resumeAndPayment.payroll.success.subTitle')}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">{t('pages.hirePlan.resumeAndPayment.payroll.title')}</Typography>
      </Grid>
      <Grid item xs={6} sm={3}>
        {t('pages.hirePlan.resumeAndPayment.payroll.selectedPlan')}
      </Grid>
      <Grid item xs={6} sm={9} className={classes.boldElement}>
        {name}
      </Grid>
      <Grid item xs={6} sm={3}>
        {t('pages.hirePlan.resumeAndPayment.payroll.finalPrice')}
      </Grid>
      <Grid item xs={6} sm={9} className={classes.boldElement}>
        {paymentsFrequency
          ? formatPriceToLocaleAndCurrency(
              paymentsFrequency.price,
              thousandsSeparator,
              decimalsSeparator,
              currencyDecimalPlaces,
              currencySymbol,
              false
            )
          : formatPriceToLocaleAndCurrency(
              price,
              thousandsSeparator,
              decimalsSeparator,
              currencyDecimalPlaces,
              currencySymbol,
              false
            )}
      </Grid>
      <Grid item xs={6} sm={3}>
        {t('pages.hirePlan.resumeAndPayment.payroll.description')}
      </Grid>
      <Grid item xs={6} sm={9} className={classes.boldElement}>
        {description}
      </Grid>
      <Grid item xs={6} sm={3}>
        {t('pages.hirePlan.resumeAndPayment.payroll.paymentType')}
      </Grid>
      <Grid item xs={6} sm={9} className={classes.boldElement}>
        {paymentTypeName}
      </Grid>
      {paymentTypeId === 2 && (
        <>
          <Grid item xs={6} sm={3}>
            {t('pages.hirePlan.resumeAndPayment.payroll.duration')}
          </Grid>
          <Grid item xs={6} sm={9} className={classes.boldElement}>
            {t(`pages.hirePlan.resumeAndPayment.payroll.months`, { month: duration })}
          </Grid>
        </>
      )}
      {paymentTypeId === 3 && (
        <>
          <Grid item xs={6} sm={3}>
            {t('pages.hirePlan.resumeAndPayment.payroll.frequency')}
          </Grid>
          <Grid item xs={6} sm={9} className={classes.boldElement}>
            {paymentsFrequency ? paymentsFrequency.frequency : frequency}
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Grid item xs={12} className={classes.containerPlanLink}>
          <Link
            href="#"
            color="inherit"
            onClick={handleDownload}
            className={classes.planLink}
            underline="hover"
          >
            {t('pages.hirePlan.planInfo.download')}
          </Link>
        </Grid>

        {paymentTypeId === 3 && (
          <Grid item xs={12} className={classes.containerCheck}>
            <FormControlLabel
              control={
                <Checkbox
                  value={authorizeRenewal}
                  onChange={onChangeAuthorizeRenewal}
                  color="primary"
                  className={classes.checkboxStyle}
                  defaultChecked
                />
              }
              label={getLabelAuthorizeRenewal()}
            />
          </Grid>
        )}

        {(dataPrivacyTerms != null || paymentTypeId === 1) && (
          <Grid item xs={12} className={classes.containerCheck}>
            <FormControlLabel
              control={
                <Checkbox
                  value={confirmInfoPlan}
                  onChange={onChangeConfirmInfoPlan}
                  color="primary"
                  className={classes.checkboxStyle}
                />
              }
              label={getCheckboxLabel()}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ResumeAndPaymentStep;
