import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  price: {
    fontFamily: 'Roboto !important',
    fontWeight: '500 !important',
    fontSize: '22px !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    letterSpacing: '0.15px !important',
    lineHeight: '0px !important',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: '18px !important',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px !important',
    },
  },
  singlePayment: {
    fontFamily: 'Roboto !important',
    fontWeight: '400 !important',
    fontSize: '12px !important',
    color: 'rgba(0, 0, 0, 0.75) !important',
    letterSpacing: '0.15px !important',
    lineHeight: '166%',
    textAlign: 'left',
  },
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '0px 16px',
    gap: '8px',
  },
  select: {
    '& .MuiInputBase-input': {
      fontSize: 12,
      padding: '2px 8px',
      background: '#FAFAFA !important',
    },
  },
  selectColorPrimary: {
    '& .MuiInputBase-input': {
      background: `transparent !important`,
    },
  },
  selectWithout: {
    '& .MuiInputBase-input': {
      background: '#FAFAFA !important',
    },
  },
  options: {
    fontSize: '12px !important',
  },
}));
