import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  default: {},

  formActions: {
    display: 'contents !important',
  },
  buttonHome: {
    background: `${theme.palette.primary.main} !important`,
    borderRadius: '10px !important',
  },
  buttonDisabled: {
    borderRadius: '10px !important',
  },
  buttonNavigation: {
    color: `${theme.palette.primary.main} !important`,
    borderRadius: '10px !important',
  },
  buttonCancel: {
    color: `${theme.palette.primary.main} !important`,
    borderColor: `${theme.palette.primary.main} !important`,
    borderRadius: '10px !important',
  },
  formCard: {
    minHeight: '29.938rem !important',
    backgroundColor: 'rgba(250, 250, 250, 1) !important',
    padding: '2rem 1.5rem 1.813rem !important',
    borderRadius: '15px !important',
    display: 'flex !important',
    justifyContent: 'center',
  },
  cardContent: {
    display: 'flex !important',
    padding: '0rem !important',
    marginBottom: '1rem !important',
  },
  containerEnd: {
    alignSelf: 'end',
  },
}));
