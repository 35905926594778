import { combineReducers } from 'redux';

import appReducer from './appReducer';
import dataPrivacyTermsReducer from './dataPrivacyTermsReducer';
import planReducer from './planReducer';

export const rootReducer = combineReducers({
  app: appReducer,
  dataPrivacyTerms: dataPrivacyTermsReducer,
  plan: planReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
