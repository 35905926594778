import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import hexToRgba from 'hex-to-rgba';

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    backgroundColor: '#FAFAFA !important',
    width: '388px',
    height: '391px',
    boxShadow:
      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    borderRadius: '15px !important',
    padding: '0px 16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifySelf: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '425px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '425px',
    },
  },
  column: {
    height: '25%',
    display: 'flex',
    alignItems: 'center',
  },
  columnDetail: {
    height: '10%',
    display: 'flex',
    alignItems: 'center',
  },
  columnDouble: {
    height: '25%',
    display: 'flex',
    alignItems: 'center',
  },
  columnChip: {
    height: '10%',
    display: 'flex',
    alignItems: 'flex-start',
  },
  name: {
    fontFamily: 'Roboto !important',
    fontWeight: '500 !important',
    fontSize: '18px !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    letterSpacing: '0.15px !important',
    lineHeight: '160% !important',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '17px !important',
    },
  },
  description: {
    fontFamily: 'Roboto !important',
    fontWeight: '400 !important',
    fontSize: '14px !important',
    color: 'rgba(0, 0, 0, 0.75) !important',
    letterSpacing: '0.15px !important',
    lineHeight: '143% !important',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textAlign: 'justify',
    padding: '0px 10px !important',
  },
  chips: {
    maxWidth: '350px',
    whiteSpace: 'nowrap',
    alignSelf: 'left',
  },
  chip: {
    borderColor: `${hexToRgba(theme.palette.primary.main, '0.5')} !important`,
    '& .MuiChip-label': {
      padding: '0px !important',
      color: 'rgba(97, 97, 97, 1) !important',
    },
    borderRadius: '10px !important',
    margin: '0px 5px !important',
    fontSize: '12px !important',
    padding: '7px 10px !important',
    height: '26px !important',
  },
  details: {
    fontFamily: 'Roboto !important',
    fontWeight: '400 !important',
    fontSize: '14px !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    letterSpacing: '0.15px !important',
    lineHeight: '143% !important',
    cursor: 'pointer',
    textDecorationColor: `${theme.palette.primary.main} !important`,
  },
  button: {
    fontFamily: 'Roboto !important',
    fontWeight: '500 !important',
    fontSize: '14px !important',
    letterSpacing: '0.46px !important',
    lineHeight: '26px !important',
    width: '124px',
    height: '36px',
    borderRadius: '10px !important',
    backgroundColor: `${theme.palette.primary.main} !important`,
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
}));
