import logo from '../assets/images/logos/Logo.png';

export const URL_LOCALHOST_DEFAULT = 'localhost:3000';
export const URL_DEFAULT = 'dev.sales.tutenlabs.dev';
export const COLOR_DEFAULT = 'rgba(63, 81, 181, 0.08)';
export const LOGO_DEFAULT = logo;
export const MAX_NUMBER_PLANS_DEFAULT = 6;
export const MAX_NUMBER_PLANS_MOBILE_DEFAULT = 1;
export const MAX_NUMBER_PLANS_MOBILE_BY_ROW_DEFAULT = 6;
export const CURRENCY_DEFAULT = 'MXN';
export const COUNTRY_DEFAULT = 'MX';
export const LANGUAJE_DEFAULT = 'es';
export const COUNTRY_PHONE_DEFAULT = 'mx';
export const GOOGLE_CAPTCHA_API_KEY = '6LduOAoaAAAAAJ-Aa84QpXxKf8oCEdv6XOE9M5-2';
export const TAG_MANAGER_TUTEN_KEY = 'GTM-WPFZCK7';
export const CURRENCY_ISO_CODE_MEXICO = 'MXN';
export const ARCHETYPE_WITH_IMAGE = 2;
export const ARCHETYPE_WITHOUT_IMAGE = 1;
export const PAYMENT_TYPE_ID_PAYROLL = 1;
export const PAYMENT_TYPE_ID_SINGLE_PAYMENT = 2;
export const PAYMENT_TYPE_ID_RECURRENT = 3;
