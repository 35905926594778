import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { checkRut, useRut } from 'react-rut-formatter';

import CustomPhoneInput from '../../../../components/atoms/CustomPhoneInput';
import InputText from '../../../../components/atoms/InputText';
import InputTextController from '../../../../components/atoms/InputTextController';
import SelectController from '../../../../components/atoms/SelectController';
import { SimpleAlert } from '../../../../components/atoms/SimpleAlert';
import {
  CountriesArrayModel,
  CountryModel,
  GendersArrayModel,
  GendersModel,
  IdentificationTypesArrayModel,
  IdentificationTypesModel,
  RegionModel,
  RegionsArrayModel,
  TitularColumnsArrayModel,
  TitularColumnsModel,
} from '../../../../models/HirePlanModel';
import {
  FORM_ADDITIONAL_FIELD_1_ID,
  FORM_ADDITIONAL_FIELD_2_ID,
  FORM_ADDITIONAL_FIELD_3_ID,
  FORM_ADDITIONAL_FIELD_4_ID,
  FORM_ADDRESS_INPUT_ID,
  FORM_DATE_OF_BIRTH_INPUT_ID,
  FORM_EMAIL_INPUT_ID,
  FORM_GENDER_INPUT_ID,
  FORM_LASTNAME_INPUT_ID,
  FORM_MOBILE_MODEL_BRAND_ID,
  FORM_NAME_INPUT_ID,
  FORM_NATIONALITY_INPUT_ID,
  FORM_PHONE_INPUT_ID,
  FORM_RELATIONSHIP_INPUT_ID,
  FORM_TYPE_AND_ID_INPUT_ID,
  RELATIONSHIP_OPTIONS,
} from '../../constants';
import { TitularInputs } from '../../models/TitularInputs';
import { useStyles } from './styles';

type TitularFormStepProps = {
  titularColumns: TitularColumnsArrayModel;
  gendersProp: GendersArrayModel;
  countriesProp: CountriesArrayModel;
  regionsProp: RegionsArrayModel;
  identificationTypesProp: IdentificationTypesArrayModel;
  control: Control<TitularInputs>;
  alert: boolean;
  watch: any;
  setValue: UseFormSetValue<TitularInputs>;
  getCommunesByRegionID: (id: number, businessUnitUUID: string | undefined) => Promise<any>;
  businessUnitUUID: string | undefined;
  validateRange: boolean;
  validateRangeHeadline: boolean;
  validateRangeMin: number;
  validateRangeMax: number;
  errors: FieldErrors<TitularInputs>;
};

const TitularFormStep = (props: TitularFormStepProps) => {
  const {
    countriesProp,
    titularColumns,
    identificationTypesProp,
    gendersProp,
    regionsProp,
    control,
    alert,
    watch,
    setValue,
    getCommunesByRegionID,
    businessUnitUUID,
    validateRange,
    validateRangeHeadline,
    validateRangeMin,
    validateRangeMax,
    errors,
  } = props;
  const { rut, updateRut } = useRut();
  const classes = useStyles();
  const { t } = useTranslation();
  const currentCity = watch(`city`);
  const [countries, setCountries] = useState<Array<object>>([]);
  const [relationships, setRelationships] = useState<Array<object>>([]);
  const [cities, setCities] = useState<Array<object>>([]);
  const [communes, setCommunes] = useState<Array<object>>([]);
  const [genders, setGenders] = useState<Array<object>>([]);
  const [idTypes, setIdTypes] = useState<Array<object>>([]);

  useEffect(() => {
    const init = async () => {
      try {
        if (currentCity !== '' && currentCity >= 0) {
          const response = await getCommunesByRegionID(currentCity, businessUnitUUID);
          const communesList = response.map(createElementList).sort(sortByLabel);
          const currentCommuneId = watch('commune');
          const currentCommune = communesList.find(
            (commune: any) => commune.value === currentCommuneId
          );
          setValue(`commune`, currentCommune ? currentCommuneId : '');
          setCommunes(communesList);
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [currentCity]);

  useEffect(() => {
    setCities(regionsProp.map(createElementList).sort(sortByLabel));
    setCountries(countriesProp.map(createElementList).sort(sortByLabel));
  }, [countriesProp, regionsProp]);

  useEffect(() => {
    setGenders(gendersProp.map(createElementList));
    setIdTypes(identificationTypesProp.map(createElementListWithName));
    setRelationships(RELATIONSHIP_OPTIONS.map(createElementWithCodeList));
  }, [identificationTypesProp, gendersProp]);

  const createElementWithCodeList = (item: IdentificationTypesModel | any) => {
    return {
      label: item.name,
      value: item.code,
    };
  };

  const createElementList = (item: CountryModel | RegionModel | GendersModel | any) => {
    return {
      label: item.name,
      value: item.id,
    };
  };

  const createElementListWithName = (item: CountryModel | RegionModel | GendersModel | any) => {
    return {
      label: item.name,
      value: item.name,
    };
  };

  const sortByLabel = (a: any, b: any) => (a.label > b.label ? 1 : -1);

  const handleRutFormat = () => {
    setValue('identificationNumber', rut.raw);
  };

  const handleGetValue = (value: string) => {
    if (watch('identificationType') === 'RUT') {
      updateRut(value);
      if (rut.raw !== watch('identificationNumber')) setValue('identificationNumber', rut.raw);
      return rut.raw;
    } else {
      return value;
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography className={classes.subtitle} variant="h6">
          {t('pages.hirePlan.titular.form.contractor.title')}
        </Typography>
      </Grid>
      {titularColumns.map((column: TitularColumnsModel, index: number) => (
        <React.Fragment key={`titular-columns-1-${index}`}>
          {column.customerColumnId === FORM_NAME_INPUT_ID && (
            <Grid item xs={12} sm={6}>
              <InputTextController
                name={'name'}
                label={t('pages.hirePlan.titular.form.name')}
                placeholder={t('pages.hirePlan.titular.form.name')}
                control={control}
                required={column.required}
                minLength={1}
                className={classes.formInput}
                onlyLetters
              />
            </Grid>
          )}
          {column.customerColumnId === FORM_LASTNAME_INPUT_ID && (
            <Grid item xs={12} sm={6}>
              <InputTextController
                name={'lastName'}
                label={t('pages.hirePlan.titular.form.lastName')}
                placeholder={t('pages.hirePlan.titular.form.lastName')}
                control={control}
                required={column.required}
                minLength={1}
                className={classes.formInput}
                onlyLetters
              />
            </Grid>
          )}
          {column.customerColumnId === FORM_TYPE_AND_ID_INPUT_ID && (
            <Grid item xs={12} sm={6}>
              <SelectController
                name={'identificationType'}
                control={control}
                data={idTypes}
                label={t('pages.hirePlan.titular.form.identificationType')}
                className={classes.formSelect}
                required={column.required}
              />
            </Grid>
          )}
          {column.customerColumnId === FORM_TYPE_AND_ID_INPUT_ID && (
            <Grid item xs={12} sm={6}>
              <Controller
                name={'identificationNumber'}
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <InputText
                    label={t('pages.hirePlan.titular.form.identificationNumber')}
                    placeholder={t('pages.hirePlan.titular.form.identificationNumber')}
                    value={handleGetValue(value)}
                    error={error}
                    variant={'outlined'}
                    onChange={(e) => {
                      if (watch('identificationType') === 'RUT') {
                        updateRut(e.target.value);
                        handleRutFormat();
                      }
                      onChange(e);
                    }}
                    className={classes.formInput}
                    fullWidth
                    minLength={1}
                    required={column.required}
                  />
                )}
                rules={{
                  required: {
                    value: column.required,
                    message: `${t(
                      'pages.hirePlan.titular.form.identificationNumber'
                    )} es requerido.`,
                  },
                  validate: {
                    rutValidator: (value) => {
                      if (watch('identificationType') === 'RUT') {
                        return checkRut(value) || 'RUT no valido';
                      } else {
                        return true;
                      }
                    },
                  },
                }}
              />
            </Grid>
          )}
          {column.customerColumnId === FORM_NATIONALITY_INPUT_ID && (
            <Grid item xs={12} sm={6}>
              <SelectController
                name={'nationality'}
                control={control}
                data={countries}
                label={t('pages.hirePlan.titular.form.nacionality')}
                className={classes.formSelect}
                required={column.required}
              />
            </Grid>
          )}
          {column.customerColumnId === FORM_RELATIONSHIP_INPUT_ID && (
            <Grid item xs={12} sm={6}>
              <SelectController
                name={'relationship'}
                control={control}
                data={relationships}
                label={t('pages.hirePlan.titular.form.relationship')}
                className={classes.formSelect}
                required={column.required}
              />
            </Grid>
          )}
          {column.customerColumnId === FORM_GENDER_INPUT_ID && (
            <Grid item xs={12} sm={6}>
              <SelectController
                name={'gender'}
                control={control}
                data={genders}
                label={t('pages.hirePlan.titular.form.gender')}
                className={classes.formSelect}
                required={column.required}
              />
            </Grid>
          )}
          {column.customerColumnId === FORM_DATE_OF_BIRTH_INPUT_ID && (
            <Grid item xs={12} sm={6}>
              <InputTextController
                name={'birthday'}
                label={t('pages.hirePlan.titular.form.birthday')}
                type={'date'}
                placeholder={t('pages.hirePlan.titular.form.birthday')}
                control={control}
                required={column.required}
                minLength={1}
                className={classes.formInput}
                typeInternal={'birthday'}
                validateRange={validateRange}
                validateRangeInternal={validateRangeHeadline}
                validateRangeMin={validateRangeMin}
                validateRangeMax={validateRangeMax}
              />
            </Grid>
          )}
        </React.Fragment>
      ))}

      <Grid item xs={12} sm={12}>
        <Typography className={classes.subtitle} variant="h6">
          {t('pages.hirePlan.titular.form.contact.title')}
        </Typography>
      </Grid>

      {titularColumns.map((column: TitularColumnsModel, index: number) => (
        <React.Fragment key={`titular-columns-2-${index}`}>
          {column.customerColumnId === FORM_EMAIL_INPUT_ID && (
            <Grid item xs={12} sm={6}>
              <InputTextController
                name={'email'}
                label={t('pages.hirePlan.titular.form.email')}
                placeholder={t('pages.hirePlan.titular.form.email')}
                type={'email'}
                control={control}
                required={column.required}
                minLength={1}
                className={classes.formInput}
              />
            </Grid>
          )}
          {column.customerColumnId === FORM_PHONE_INPUT_ID && (
            <Grid item xs={12} sm={6}>
              <CustomPhoneInput
                name={'phoneNumber'}
                control={control}
                label={t('pages.hirePlan.titular.form.phoneNumber')}
                required={column.required}
                variant={'outlined'}
              />
            </Grid>
          )}
          {column.customerColumnId === FORM_ADDRESS_INPUT_ID && (
            <>
              <Grid item xs={12} sm={6}>
                <SelectController
                  name={'city'}
                  control={control}
                  data={cities}
                  label={t('pages.hirePlan.titular.form.city')}
                  className={classes.formSelect}
                  required={column.required}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectController
                  name={'commune'}
                  control={control}
                  data={communes}
                  disabled={communes.length === 0}
                  label={t('pages.hirePlan.titular.form.commune')}
                  className={classes.formSelect}
                  required={column.required}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputTextController
                  name={'address'}
                  label={t('pages.hirePlan.titular.form.address')}
                  placeholder={t('pages.hirePlan.titular.form.address')}
                  control={control}
                  required={column.required}
                  minLength={1}
                  className={classes.formInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputTextController
                  name={'number'}
                  label={t('pages.hirePlan.titular.form.number')}
                  type={'number'}
                  placeholder={t('pages.hirePlan.titular.form.number')}
                  control={control}
                  required={column.required}
                  minLength={1}
                  className={classes.formInput}
                />
              </Grid>
            </>
          )}
        </React.Fragment>
      ))}

      {titularColumns.some(
        (column: TitularColumnsModel) =>
          column.customerColumnId === FORM_MOBILE_MODEL_BRAND_ID ||
          column.customerColumnId === FORM_ADDITIONAL_FIELD_1_ID ||
          column.customerColumnId === FORM_ADDITIONAL_FIELD_2_ID ||
          column.customerColumnId === FORM_ADDITIONAL_FIELD_3_ID ||
          column.customerColumnId === FORM_ADDITIONAL_FIELD_4_ID
      ) && (
        <Grid item xs={12} sm={12}>
          <Typography className={classes.subtitle} variant="h6">
            {t('pages.hirePlan.titular.form.additionalInformation.title')}
          </Typography>
        </Grid>
      )}

      {titularColumns.map((column: TitularColumnsModel, index: number) => (
        <React.Fragment key={`titular-columns-3-${index}`}>
          {column.customerColumnId === FORM_MOBILE_MODEL_BRAND_ID && (
            <React.Fragment>
              <Grid item xs={12} sm={6}>
                <InputTextController
                  name={'mobileBrand'}
                  label={t('pages.hirePlan.titular.form.mobileBrand.label')}
                  placeholder={t('pages.hirePlan.titular.form.mobileBrand.placeholder')}
                  control={control}
                  required={column.required}
                  minLength={1}
                  className={classes.formInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputTextController
                  name={'mobileModel'}
                  label={t('pages.hirePlan.titular.form.mobileModel.label')}
                  placeholder={t('pages.hirePlan.titular.form.mobileModel.placeholder')}
                  control={control}
                  required={column.required}
                  minLength={1}
                  className={classes.formInput}
                />
              </Grid>
            </React.Fragment>
          )}
          {column.customerColumnId === FORM_ADDITIONAL_FIELD_1_ID && (
            <Grid item xs={12} sm={6}>
              <InputTextController
                name={'additionalField1'}
                label={t('pages.hirePlan.titular.form.additionalField1.label')}
                placeholder={t('pages.hirePlan.titular.form.genericText.placeholder')}
                control={control}
                required={column.required}
                minLength={1}
                className={classes.formInput}
              />
            </Grid>
          )}
          {column.customerColumnId === FORM_ADDITIONAL_FIELD_2_ID && (
            <Grid item xs={12} sm={6}>
              <InputTextController
                name={'additionalField2'}
                label={t('pages.hirePlan.titular.form.additionalField2.label')}
                placeholder={t('pages.hirePlan.titular.form.genericText.placeholder')}
                control={control}
                required={column.required}
                minLength={1}
                className={classes.formInput}
              />
            </Grid>
          )}
          {column.customerColumnId === FORM_ADDITIONAL_FIELD_3_ID && (
            <Grid item xs={12} sm={6}>
              <InputTextController
                name={'additionalField3'}
                label={t('pages.hirePlan.titular.form.additionalField3.label')}
                placeholder={t('pages.hirePlan.titular.form.genericText.placeholder')}
                control={control}
                required={column.required}
                minLength={1}
                className={classes.formInput}
              />
            </Grid>
          )}
          {column.customerColumnId === FORM_ADDITIONAL_FIELD_4_ID && (
            <Grid item xs={12} sm={6}>
              <InputTextController
                name={'additionalField4'}
                label={t('pages.hirePlan.titular.form.additionalField4.label')}
                placeholder={t('pages.hirePlan.titular.form.genericText.placeholder')}
                control={control}
                required={column.required}
                minLength={1}
                className={classes.formInput}
              />
            </Grid>
          )}
        </React.Fragment>
      ))}

      {alert && (
        <Grid item xs={12} sm={12}>
          <SimpleAlert
            type={'error'}
            textTitle={t('pages.hirePlan.titular.alert.title')}
            text={t('pages.hirePlan.titular.alert.body')}
            fullWidth
          />
        </Grid>
      )}
      {errors.birthday && (
        <Grid item xs={12} sm={12}>
          <SimpleAlert
            type={'error'}
            textTitle={t('pages.hirePlan.titular.alert.title.birthday')}
            text={t('pages.hirePlan.titular.alert.message.birthday', {
              validateRangeMin,
              validateRangeMax,
            })}
            fullWidth
          />
        </Grid>
      )}
    </Grid>
  );
};

export default TitularFormStep;
