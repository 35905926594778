import { SET_CURRENT_PLAN_ID_TO_SHOW_DETAILS } from '../constants/planActionTypes';
import { PlanActions, PlanState } from '../constants/planTypes';

const initialState: PlanState = {
  currentPlanToShowDetails: {},
  planToShowDetailsId: undefined,
};

export default (state = initialState, action: PlanActions) => {
  if (action.type === SET_CURRENT_PLAN_ID_TO_SHOW_DETAILS) {
    return {
      ...state,
      planToShowDetailsId: action.planToShowDetailsId,
    };
  } else {
    return {
      ...state,
    };
  }
};
