import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontFamily: 'Roboto !important',
    fontSize: '34px !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    letterSpacing: '0.25px !important',
    lineHeight: '123.5% !important',
    fontWeight: '500 !important',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '10px !important',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px !important',
    },
  },
  button: {
    fontFamily: 'Roboto !important',
    fontWeight: '500 !important',
    fontSize: '14px !important',
    letterSpacing: '0.46px !important',
    lineHeight: '26px !important',
    width: '250px',
    height: '36px',
    borderRadius: '10px',
    border: `1px solid ${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.main} !important`,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      gap: '10px',
    },
  },
}));
