import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const localUseStyles = makeStyles((theme: Theme) => ({
  viewMoreLink: {
    whiteSpace: 'normal',
    marginLeft: '36px !important',
    fontSize: '14px !important',
    fontFamily: 'Roboto !important',
    fontWeight: '400 !important',
    color: `${theme.palette.primary.main} !important`,
    marginBottom: '17px !important',
    marginTop: '10px !important',
  },
}));
