import AddIcon from '@mui/icons-material/Add';
import { Alert, Button, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import {
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SimpleAlert } from '../../../../components/atoms/SimpleAlert';
import {
  BeneficiaryColumnsArrayModel,
  CountriesArrayModel,
  GendersArrayModel,
  IdentificationTypesArrayModel,
  RegionsArrayModel,
} from '../../../../models/HirePlanModel';
import { BeneficiaryInputs, BeneficiaryList } from '../../models/BeneficiaryInputs';
import { TitularInputs } from '../../models/TitularInputs';
import BeneficiaryFormElement from './BeneficiaryFormElement';
import { useStyles } from './styles';

type StepProps = {
  beneficiaryColumns: BeneficiaryColumnsArrayModel;
  gendersProp: GendersArrayModel;
  identificationTypesProp: IdentificationTypesArrayModel;
  countriesProp: CountriesArrayModel;
  regionsProp: RegionsArrayModel;
  control: Control<BeneficiaryList>;
  alert: boolean;
  alertIdentification: boolean;
  isIdentificationType: boolean | undefined;
  alertEmail: boolean;
  minimumBeneficiaries: number;
  maximumBeneficiaries: number;
  setValue: UseFormSetValue<BeneficiaryList>;
  watch: UseFormWatch<BeneficiaryList>;
  watchTitular: UseFormWatch<TitularInputs>;
  beneficiaryAppend: any;
  beneficiaryRemove: any;
  beneficiaryList: any;
  getCommunesByRegionID: (id: number, businessUnitUUID: string | undefined) => Promise<any>;
  identificationChange: (value: string | number) => boolean;
  triggerBeneficiary: UseFormTrigger<BeneficiaryList>;
  businessUnitUUID: string | undefined;
  validateRange: boolean;
  validateRangeBeneficiary: boolean;
  validateRangeMin: number;
  validateRangeMax: number;
  errors: FieldErrors<BeneficiaryList>;
};

const BeneficiaryFormStep = (props: StepProps) => {
  const {
    beneficiaryList,
    beneficiaryAppend,
    beneficiaryRemove,
    beneficiaryColumns,
    gendersProp,
    countriesProp,
    regionsProp,
    identificationTypesProp,
    minimumBeneficiaries,
    maximumBeneficiaries,
    control,
    alert,
    alertIdentification,
    alertEmail,
    isIdentificationType,
    watch,
    watchTitular,
    setValue,
    getCommunesByRegionID,
    identificationChange,
    triggerBeneficiary,
    businessUnitUUID,
    validateRange,
    validateRangeBeneficiary,
    validateRangeMin,
    validateRangeMax,
    errors,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    if (beneficiaryList.length === 0) {
      beneficiaryAppend({
        name: '',
        lastName: '',
        identificationType: '',
        identificationNumber: '',
        phoneNumber: '',
        email: '',
        relationship: '',
        nationality: '',
        gender: '',
        birthday: '',
        city: '',
        commune: '',
        address: '',
        number: '',
        mobileBrand: '',
        mobileModel: '',
        additionalField1: '',
        additionalField2: '',
        additionalField3: '',
        additionalField4: '',
        isSameData: false,
      });
    }
  }, []);

  const addBeneficiary = () => {
    beneficiaryAppend({
      name: '',
      lastName: '',
      identificationType: '',
      identificationNumber: '',
      phoneNumber: '',
      email: '',
      relationship: '',
      nationality: '',
      gender: '',
      birthday: '',
      city: '',
      commune: '',
      address: '',
      number: '',
      mobileBrand: '',
      mobileModel: '',
      additionalField1: '',
      additionalField2: '',
      additionalField3: '',
      additionalField4: '',
      isSameData: false,
    });
  };

  const getMessage = (minimumBeneficiaries: number, maximumBeneficiaries: number) => {
    if (minimumBeneficiaries === 1 && maximumBeneficiaries) {
      return t('pages.hirePlan.beneficiary.alert.type1', { maximumBeneficiaries });
    }

    if (minimumBeneficiaries > 1 && !maximumBeneficiaries) {
      return t('pages.hirePlan.beneficiary.alert.type2', { minimumBeneficiaries });
    }

    if (
      minimumBeneficiaries > 1 &&
      maximumBeneficiaries &&
      minimumBeneficiaries !== maximumBeneficiaries
    ) {
      return t('pages.hirePlan.beneficiary.alert.type3', {
        minimumBeneficiaries,
        maximumBeneficiaries,
      });
    }

    if (
      minimumBeneficiaries > 1 &&
      maximumBeneficiaries &&
      minimumBeneficiaries === maximumBeneficiaries
    ) {
      return t('pages.hirePlan.beneficiary.alert.type4', { minimumBeneficiaries });
    }

    return null;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h6">{t('pages.hirePlan.beneficiary.main.title')}</Typography>
      </Grid>
      <Grid item xs={12}>
        {beneficiaryList.map((currentBeneficiary: BeneficiaryInputs, indexBeneficiary: number) => {
          return (
            <BeneficiaryFormElement
              key={currentBeneficiary.id}
              id={indexBeneficiary}
              beneficiaryColumns={beneficiaryColumns}
              gendersProp={gendersProp}
              countriesProp={countriesProp}
              regionsProp={regionsProp}
              identificationTypesProp={identificationTypesProp}
              control={control}
              alert={alert}
              watch={watch}
              setValue={setValue}
              beneficiaryRemove={beneficiaryRemove}
              watchTitular={watchTitular}
              getCommunesByRegionID={getCommunesByRegionID}
              identificationChange={identificationChange}
              isIdentificationType={isIdentificationType}
              triggerBeneficiary={triggerBeneficiary}
              totalBeneficaries={beneficiaryList.length}
              businessUnitUUID={businessUnitUUID}
              validateRange={validateRange}
              validateRangeBeneficiary={validateRangeBeneficiary}
              validateRangeMin={validateRangeMin}
              validateRangeMax={validateRangeMax}
            />
          );
        })}
      </Grid>
      {alertIdentification && (
        <Grid item xs={12}>
          <Alert severity="error" className={classes.alertError}>
            {t('pages.hirePlan.beneficiary.alert.identification')}
          </Alert>
        </Grid>
      )}
      {alertEmail && (
        <Grid item xs={12}>
          <Alert severity="error" className={classes.alertError}>
            {t('pages.hirePlan.beneficiary.alert.email')}
          </Alert>
        </Grid>
      )}
      {alert && (
        <Grid item xs={12} sm={12}>
          <SimpleAlert
            type={'error'}
            textTitle={t('pages.hirePlan.beneficiary.alert.title')}
            text={t('pages.hirePlan.beneficiary.alert.body')}
            fullWidth
          />
        </Grid>
      )}
      {errors.beneficiaries?.map((beneficiary) => {
        if (beneficiary && beneficiary.birthday) {
          return (
            <Grid item xs={12} sm={12}>
              <SimpleAlert
                type={'error'}
                textTitle={t('pages.hirePlan.beneficiary.alert.title.birthday')}
                text={t('pages.hirePlan.beneficiary.alert.message.birthday', {
                  validateRangeMin,
                  validateRangeMax,
                })}
                fullWidth
              />
            </Grid>
          );
        } else {
          return null;
        }
      })}
      <Grid item xs={12} className={classes.endContainer}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            {getMessage(minimumBeneficiaries, maximumBeneficiaries) !== null && (
              <Alert severity="info" className={classes.alert}>
                {getMessage(minimumBeneficiaries, maximumBeneficiaries)}
              </Alert>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.containerButton}>
            <Button
              startIcon={<AddIcon />}
              variant="outlined"
              className={
                beneficiaryList.length >= maximumBeneficiaries ? classes.default : classes.button
              }
              onClick={addBeneficiary}
              disabled={beneficiaryList.length >= maximumBeneficiaries}
            >
              {t('pages.hirePlan.beneficiary.button.add')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BeneficiaryFormStep;
