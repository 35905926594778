import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomBreadcumbs from '../../components/CustomBreadcumbs';
import ContainerPage from '../../components/Layout/ContainerPage';
import { DATA_PRIVACY_TERMS } from '../../constants/routes';
import { DATA_PRIVACY_TERMS_VIEW_NAME } from '../../constants/views';
import { setCurrentViewLink, setCurrentViewName } from '../../redux/actions/appActions';
import { getDataPrivacyTermsSelector } from '../../redux/selectors/index';

const DataPrivacyTerms = () => {
  const dispatch = useDispatch();
  const { dataPrivacyTerms } = useSelector(getDataPrivacyTermsSelector);

  useEffect(() => {
    dispatch(setCurrentViewName({ currentViewName: DATA_PRIVACY_TERMS_VIEW_NAME }));
    dispatch(setCurrentViewLink({ currentViewLink: DATA_PRIVACY_TERMS }));
  }, []);

  return (
    <ContainerPage>
      <CustomBreadcumbs />
      <Typography
        dangerouslySetInnerHTML={{
          __html: dataPrivacyTerms != null ? dataPrivacyTerms : '',
        }}
      />
    </ContainerPage>
  );
};

export default DataPrivacyTerms;
