export const HOME = '/';
export const PLANS = '/plans';
export const CATEGORY_ID = '/category';
export const CATEGORY_PLAN_ID = '/plan';
export const FREQUENT_QUESTIONS = '/frequent-questions';
export const TERMS_AND_CONDITIONS = '/terms-and-conditions';
export const LEGAL_NOTICES = '/legal-notices';
export const CONTACT_US = '/contact-us';
export const HIRE_PLAN = '/hire-plan';
export const DATA_PRIVACY_TERMS = '/dataPrivacyTerms';
